import React from "react";
import classes from '../HostelRoom/HostelRoom.module.css';
// import logo from "../../assets/FUNAAB-Logo 1.png"
// import {Routes, Route} from 'react-router-dom';
// import Login from "./Pages/Login/Login"
// import girlImg from "../../assets/girlImg.png";
import pfp from "../../Assets/pfp.png";
// import pysf from '../../assets/payurfees.png'
// import hostlfees from '../../assets/hostlfees.png'
// import fundurwlt from '../../assets/fundurwlt.png'
// import chekurrslt from '../../assets/chkurwlt.png'
// import gturcrsmtrls from '../../assets/gturcrsmtrls.png'
// import rgstrurcurses from '../../assets/rgstrurcurses.png'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import htelicon from '../../Assets/undraw_coming_home_re_ausc 1.png'
import grey from '../../Assets/lightgrey.svg'
import green from '../../Assets/greenicon.svg'
import dgrey from '../../Assets/greyicon.svg'
import room1 from '../../Assets/room1.svg'
import room2 from '../../Assets/room2.svg'
import room3 from '../../Assets/room3.svg'
import room4 from '../../Assets/room4.svg'
import room5 from '../../Assets/room5.svg'
import room6 from '../../Assets/room6.svg'
import room7 from '../../Assets/room7.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'

function HostelRoom() {
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>Hostel Fees</p>
                    {/* <p className={classes.qlnktxt}>Quicklinks</p> */}
                    {/* <div className={classes.qlnks}>
                    
                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>

                    <div className={classes.frstcolumn}>

                        <div className={classes.frstsubcolumn}>
                            <img src={gturcrsmtrls} className={classes.gturcrsmtrls} />
                            <img src={chekurrslt} className={classes.chekurrslt} />
                        </div>
                        <img src={rgstrurcurses} className={classes.rgstrurcurses} />
                    </div>

                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>
                </div> */}
                    <div className={classes.dashBoardDiv}>
                        <div className={classes.topHeader}>
                            <h4>Select your room in Hostel A</h4>
                        </div>
                        <div className={classes.hostbdy}>
                            <div className={classes.choiceBdy}>
                                <div className={classes.hostelChoice}>
                                    <img src={grey} />
                                    <p>Available</p>
                                </div>
                                <div className={classes.hostelChoice}>
                                    <img src={green} />
                                    <p>Selected</p>
                                </div>
                                <div className={classes.hostelChoice}>
                                    <img src={dgrey} />
                                    <p>Not Available</p>
                                </div>
                            </div>
                            <div className={classes.grdbtn}>
                                <select name='floor' id='floor'>
                                    <option value='Ground Floor'>Ground Floor</option>
                                </select>
                            </div>
                        </div>

                        <div className={classes.pghostel}>
                            <p>If the ground floor is filled up, check out the other floors</p>
                        </div>

                        <div>
                            <div className={classes.roomicons}>
                                <div>
                                    <img src={room1} />
                                    <p>Room 101</p>
                                </div>
                                <div>
                                    <img src={room2} />
                                    <p>Room 102</p>
                                </div>
                                <div>
                                    <img src={room3} />
                                    <p>Room 103</p>
                                </div>
                                <div>
                                    <img src={room4} />
                                    <p>Room 104</p>
                                </div>
                                <div>
                                    <img src={room5} />
                                    <p>Room 105</p>
                                </div>
                                <div>
                                    <img src={room6} />
                                    <p>Room 106</p>
                                </div>
                            </div>
                            <div className={classes.roomicons}>
                                <div>
                                    <img src={room7} />
                                    <p>Room 107</p>
                                </div>
                                <div>
                                    <img src={room3} />
                                    <p>Room 108</p>
                                </div>
                                <div>
                                    <img src={room3} />
                                    <p>Room 109</p>
                                </div>
                                <div>
                                    <img src={room4} />
                                    <p>Room 110</p>
                                </div>
                                <div>
                                    <img src={room5} />
                                    <p>Room 111</p>
                                </div>
                                <div>
                                    <img src={room6} />
                                    <p>Room 112</p>
                                </div>
                            </div>
                        </div>
                        <Link to={'/hostelfeessum'}><button>Make payment for room space</button></Link>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default HostelRoom;
