import React from "react";
import classes from './MakePaymentOther.module.css';
// import logo from "../../assets/FUNAAB-Logo 1.png"
// import {Routes, Route} from 'react-router-dom';
// import Login from "./Pages/Login/Login"
// import girlImg from "../../Assets/girlImg.png";
// import pfp from "../../Assets/pfp.png";
// import pysf from '../../Assets/payurfees.png'
// import hostlfees from '../../Assets/hostlfees.png'
// import fundurwlt from '../../Assets/fundurwlt.png'
// import chekurrslt from '../../Assets/chkurwlt.png'
// import gturcrsmtrls from '../../Assets/gturcrsmtrls.png'
// import rgstrurcurses from '../../Assets/rgstrurcurses.png'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import backIcon from '../../Assets/backIcon.png';

function BioData() {
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>School Fees</p>
                    {/* <p className={classes.qlnktxt}>Quicklinks</p> */}
                    {/* <div className={classes.qlnks}>
                    
                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>

                    <div className={classes.frstcolumn}>

                        <div className={classes.frstsubcolumn}>
                            <img src={gturcrsmtrls} className={classes.gturcrsmtrls} />
                            <img src={chekurrslt} className={classes.chekurrslt} />
                        </div>
                        <img src={rgstrurcurses} className={classes.rgstrurcurses} />
                    </div>

                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>
                </div> */}
                    <div className={classes.dashBoardDiv}>
                        <div className={classes.backTxt}>
                            <img src={backIcon} className={classes.backIcon}/>
                            <small>Other Fees</small>
                        </div>
                        <div className={classes.innerDiv}>
                            <div>
                            <p className={classes.mkPayTxt}>Make Payment</p>
                            <div className={classes.semesterInfo}>
                                <p className={classes.semesterInfoTxt}>Field Trip for 200 level students to NIITDA</p>
                                <p className={classes.semesterAmt}>N30,000</p>
                            </div>
                            </div>
          
                        
                            <div className={classes.semesterInfo2}>
                                <div>
                                    <p className={classes.payntModTxt}>Select Payment Method</p>
                                    <div className={classes.spaceBtwOpts}>
                                        <div className={classes.opts}>
                                            <input type="radio" id="payWithMe" name="payment"/>
                                            <label for="payWithMe" className={classes.container}>
                                                <p>
                                                    Pay with Wallet balance<br/>
                                                    <span>Wallet balance - N150,000</span>
                                                </p>
                                            </label>
                                        </div>
                                        <div className={classes.pts}>
                                            <input type="radio" id="payWithMe" name="payment"/>
                                            <label for="payWithMe" className={classes.container}>
                                                <p>
                                                Make your payment with remita payment system<br/>
                                                    <span>Pay with Remita</span>
                                                </p>
                                            </label>
                                        </div> 
                                    </div>
                                </div>
                                <div className={classes.pymtDetails}>
                                    <h4>Payment details</h4>
                                    <div className={classes.tablez}>
                                        <p>Subtotal</p>
                                        <p>N30,000</p>
                                    </div>
                                    <div className={classes.tablez2}>
                                        <p>Tax</p>
                                        <p>0</p>
                                    </div>
                                    <div className={classes.lin2}/>
                                    <div className={classes.tablez}>
                                        <p>Total</p>
                                        <p>N30,000</p>
                                    </div>
                                    <Link to={'/other_fees_paid'}>
                                    <button className={classes.makePamtBtn}>
                                        Make Payment
                                    </button>
                                    </Link>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default BioData;
