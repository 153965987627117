import React from "react";
import classes from './PaidFee.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import confirmImg from "../../Assets/confirmImg.png";

function Login() {
  return (
    <div>
      <div className={classes.appcontainer}>
        <div className={classes.sidenav}>
          <DashboardNav className={classes.scrollable} />
        </div>

        <div className={classes.maincontent}>
          <Horheader />
          <p className={classes.dshbrdtxt}>Registration</p>

          <div className={classes.dashBoardDiv}>
            <div className={classes.innerDiv}>
              <img src={confirmImg} className={classes.girlImg} />
              <p className={classes.txt}>Your school fees has been paid</p>
              <Link to={'/nothing'}>
                <button className={classes.dashBoardBtn}>
                Print Receipt
                </button>
              </Link>
            </div>
          </div>

        </div>

      </div>
    </div>

  );
}

export default Login;
