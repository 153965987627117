import React from "react";
import classes from '../UploadAssignment/UploadAssignment.module.css';
// import logo from "../../assets/FUNAAB-Logo 1.png"
// import {Routes, Route} from 'react-router-dom';
// import Login from "./Pages/Login/Login"
// import girlImg from "../../assets/girlImg.png";
import pfp from "../../Assets/pfp.png";
// import pysf from '../../assets/payurfees.png'
// import hostlfees from '../../assets/hostlfees.png'
// import fundurwlt from '../../assets/fundurwlt.png'
// import chekurrslt from '../../assets/chkurwlt.png'
// import gturcrsmtrls from '../../assets/gturcrsmtrls.png'
// import rgstrurcurses from '../../assets/rgstrurcurses.png'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import back from '../../Assets/backicon.svg'
import bookicon from '../../Assets/bookicon.svg'
import book from '../../Assets/book.svg'
import upload from '../../Assets/upload.svg'
import prevbook from '../../Assets/prevbook.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'

function UploadAssignment() {
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>Assignments</p>
                    {/* <p className={classes.qlnktxt}>Quicklinks</p> */}
                    {/* <div className={classes.qlnks}>
                    
                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>

                    <div className={classes.frstcolumn}>

                        <div className={classes.frstsubcolumn}>
                            <img src={gturcrsmtrls} className={classes.gturcrsmtrls} />
                            <img src={chekurrslt} className={classes.chekurrslt} />
                        </div>
                        <img src={rgstrurcurses} className={classes.rgstrurcurses} />
                    </div>

                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>
                </div> */}
                    <div className={classes.dashBoardDiv}>
                        <div className={classes.subContainer}>
                            <img src={back} />
                            <p>Assignments</p>
                        </div>
                        <h4>Submit CSC 201 Assignment</h4>
                        <div className={classes.assignmentcontainer}>

                            <div className={classes.Topgroup}>
                                <div className={classes.formgroup}>
                                    <label for="title">Enter Title of Assignment</label> <br />
                                    <input type="text" id="title" className={classes.forminput} placeholder="Enter Title of Assignment" />
                                </div>
                                <div className={classes.formgroup}>
                                    <label>Upload assignment here</label>
                                    <div className={classes.uploadarea}>
                                        <span className={classes.uploadicon}>
                                            <img src={upload} />
                                        </span>
                                        <span className={classes.uploadtext}>Upload your document here</span><br />
                                        Upload
                                        <input type="file" className={classes.uploadinput} />
                                    </div>
                                </div>
                                <Link to={'/assignconf'}><button className={classes.submitbutton}>Submit Assignment</button></Link>
                            </div>

                            <div className={classes.Topprev}>
                                <div className={classes.previousassignments}>
                                    <h4>Previous Assignments</h4>
                                    <ul className={classes.assignmentlist}>
                                        <div className={classes.assignmentcard}>
                                            <img src={prevbook} alt="Icon" className={classes.icon} />
                                            <div className={classes.assignmentinfo}>
                                                <h4 className={classes.assignheader}>5 computer language models</h4>
                                                <p>There are ten basic language models but here are five major computer language models...</p>
                                                <span className={classes.date}>Submitted on 24th April, 2024 - 12:53pm</span>
                                            </div>
                                        </div>
                                    </ul>

                                    <ul className={classes.assignmentlist}>
                                        <div className={classes.assignmentcard}>
                                            <img src={prevbook} alt="Icon" className={classes.icon} />
                                            <div className={classes.assignmentinfo}>
                                                <h4 className={classes.assignheader}>5 computer language models</h4>
                                                <p>There are ten basic language models but here are five major computer language models...</p>
                                                <span className={classes.date}>Submitted on 24th April, 2024 - 12:53pm</span>
                                            </div>
                                        </div>
                                    </ul>

                                    <ul className={classes.assignmentlist}>
                                        <div className={classes.assignmentcard}>
                                            <img src={prevbook} alt="Icon" className={classes.icon} />
                                            <div className={classes.assignmentinfo}>
                                                <h4 className={classes.assignheader}>5 computer language models</h4>
                                                <p>There are ten basic language models but here are five major computer language models...</p>
                                                <span className={classes.date}>Submitted on 24th April, 2024 - 12:53pm</span>
                                            </div>
                                        </div>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>

    );
};

export default UploadAssignment;
