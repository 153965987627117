import React from "react";
import classes from './BioData.module.css';
// import logo from "../../assets/FUNAAB-Logo 1.png"
// import {Routes, Route} from 'react-router-dom';
// import Login from "./Pages/Login/Login"
// import girlImg from "../../assets/girlImg.png";
import pfp from "../../Assets/pfp.png";
// import pysf from '../../assets/payurfees.png'
// import hostlfees from '../../assets/hostlfees.png'
// import fundurwlt from '../../assets/fundurwlt.png'
// import chekurrslt from '../../assets/chkurwlt.png'
// import gturcrsmtrls from '../../assets/gturcrsmtrls.png'
// import rgstrurcurses from '../../assets/rgstrurcurses.png'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'

function BioData() {
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>Biodata</p>
                    {/* <p className={classes.qlnktxt}>Quicklinks</p> */}
                    {/* <div className={classes.qlnks}>  
                    
                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>

                    <div className={classes.frstcolumn}>

                        <div className={classes.frstsubcolumn}>
                            <img src={gturcrsmtrls} className={classes.gturcrsmtrls} />
                            <img src={chekurrslt} className={classes.chekurrslt} />
                        </div>
                        <img src={rgstrurcurses} className={classes.rgstrurcurses} />
                    </div>

                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>
                </div> */}
                    <div className={classes.dashBoardDiv}>
                        <div className={classes.smth}>
                            <div className={classes.dataInfo}>
                                <p className={classes.stdInfoTxt}>Student Information</p>
                                <label>Name</label>
                                <p className={classes.ans}>Amaka Andersen</p>

                                <label>Matric Number</label>
                                <p className={classes.ans}>CSC0034578</p>

                                <label>State of Origin</label>
                                <p className={classes.ans}>Ogun State</p>

                                <label>LGA</label>
                                <p className={classes.ans}>Abeokuta North</p>

                                <label>Entry Mode</label>
                                <p className={classes.ans}>100 level</p>

                                <label>Current Level</label>
                                <p className={classes.ans}>200 Level</p>

                                <label>Sex</label>
                                <p className={classes.ans}>Female</p>

                                <label>Faculty</label>
                                <p className={classes.ans}>School of Science</p>

                                <label>Department</label>
                                <p className={classes.ans}>Computer Science</p>

                                <label>Programme</label>
                                <p className={classes.ans}>B.Sc. Computer Science</p>

                                <label>Class of Degree</label>
                                <p className={classes.ans}>Second Class Lower</p>
                            </div>

                            <div className={classes.dataInfo}>
                                <p className={classes.stdInfoTxt}>Profile Photo</p>
                                <img src={pfp} className={classes.pfp} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default BioData;
