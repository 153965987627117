import React from "react";
import classes from '../Wallet/Wallet.module.css';
// import logo from "../../assets/FUNAAB-Logo 1.png"
// import {Routes, Route} from 'react-router-dom';
// import Login from "./Pages/Login/Login"
// import girlImg from "../../assets/girlImg.png";
import pfp from "../../Assets/pfp.png";
// import pysf from '../../assets/payurfees.png'
// import hostlfees from '../../assets/hostlfees.png'
// import fundurwlt from '../../assets/fundurwlt.png'
// import chekurrslt from '../../assets/chkurwlt.png'
// import gturcrsmtrls from '../../assets/gturcrsmtrls.png'
// import rgstrurcurses from '../../assets/rgstrurcurses.png'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import person1 from '../../Assets/person1.svg'
import stroke from '../../Assets/stroke.svg'
import person2 from '../../Assets/person2.svg'
import person3 from '../../Assets/person3.svg'
import wallet from '../../Assets/wallet.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'

function Wallet() {
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>Wallet</p>
                    {/* <p className={classes.qlnktxt}>Quicklinks</p> */}
                    {/* <div className={classes.qlnks}>
                    
                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>

                    <div className={classes.frstcolumn}>

                        <div className={classes.frstsubcolumn}>
                            <img src={gturcrsmtrls} className={classes.gturcrsmtrls} />
                            <img src={chekurrslt} className={classes.chekurrslt} />
                        </div>
                        <img src={rgstrurcurses} className={classes.rgstrurcurses} />
                    </div>

                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>
                </div> */}
                    <div className={classes.dashBoardDiv}>
                        <div className={classes.walletTop}>
                            

                            <div className={classes.wallet}>
                                <img className={classes.walletimg1} src={person1} />
                                <Link to={'/walletbal'}><h4>Pay from Wallet</h4></Link>
                                <p>You can now pay your school fees and other fees from your
wallet, simply follow the following steps to create your wallet,
fund your wallet and make payment.</p>
                                <img className={classes.walletimg2} src={stroke}></img>
                            </div>

                            <div className={classes.wallet}>
                                <img className={classes.walletimg1} src={person2} />
                                <Link to={'/virtualwallet'} ><h4>Create Wallet</h4></Link>
                                <p>Tap on the create wallet button below to create your virtual
                                    bank account number for your wallet.</p>
                                <img className={classes.walletimg2} src={stroke}></img>
                            </div> 

                            <div className={classes.wallet}>
                                <img className={classes.walletimg1} src={person2} />
                                <Link to={'/fundacct'}><h4>Fund wallet, make payment</h4></Link>
                                <p>Make a bank transfer into the virtual account number you just
created and it will reflect in your wallet, then use your wallet
to pay for your school fees and other fees.</p>
                                <img className={classes.walletimg2} src={stroke}></img>
                            </div>

                            {/* <div className={classes.wallet}>
                                <img className={classes.walletimg1} src={person3} />
                                <h4>Fund wallet, make payment</h4>
                                <p>Make a bank transfer into the virtual account number you just
                                    created and it will reflect in your wallet, then use your wallet
                                    to pay for your school fees and other fees.</p>
                                <img className={classes.walletimg2} src={stroke}></img>
                            </div> */}

                        </div>

                        <div className={classes.walletBtn}>
                            <img src={wallet} />
                            <h4>Create your virtual account to fund your wallet</h4>
                           <Link to={'/virtualwallet'} ><button>Create account</button></Link>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Wallet;
