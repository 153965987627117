import React from "react";
import classes from './Senate2.module.css';
// import logo from "../../assets/FUNAAB-Logo 1.png"
// import {Routes, Route} from 'react-router-dom';
// import Login from "./Pages/Login/Login"
// import girlImg from "../../Assets/girlImg.png";
// import pfp from "../../Assets/pfp.png";
// import pysf from '../../Assets/payurfees.png'
// import hostlfees from '../../Assets/hostlfees.png'
// import fundurwlt from '../../Assets/fundurwlt.png'
// import chekurrslt from '../../Assets/chkurwlt.png'
// import gturcrsmtrls from '../../Assets/gturcrsmtrls.png'
// import rgstrurcurses from '../../Assets/rgstrurcurses.png'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import alertIcon from '../../Assets/alertIcon.png'
import newsPlace from '../../Assets/newsplace.png'

function BioData() {
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>Senate Affairs</p>
                    {/* <p className={classes.qlnktxt}>Quicklinks</p> */}
                    {/* <div className={classes.qlnks}>
                    
                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>

                    <div className={classes.frstcolumn}>

                        <div className={classes.frstsubcolumn}>
                            <img src={gturcrsmtrls} className={classes.gturcrsmtrls} />
                            <img src={chekurrslt} className={classes.chekurrslt} />
                        </div>
                        <img src={rgstrurcurses} className={classes.rgstrurcurses} />
                    </div>

                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>
                </div> */}
                    <div className={classes.dashBoardDiv}>
                        <div className={classes.senateAff}>
                            <small className={classes.sll}>Infractions</small>
                            <div className={classes.senateAffCon}>
                                <img src={alertIcon} className={classes.alertIcon}/>
                                <div className={classes.info}>
                                    <p className={classes.aGoodTxt}>Exam Malpractice Offence</p>
                                    <p className={classes.noInf}>An exam malpractice offence has been filed against you on the 12th of April 2024, report to the Senate discplinary committee on the 15th of May 2024<br/>
by 9am to answer for this offence</p>
                                </div>
                            </div>

                            <div>
                                <small className={classes.sll}>School News</small>
                                <div className={classes.scNews}>
                                    <img src={newsPlace} className={classes.newsPlaceImg}/>
                                    <div>
                                        <p className={classes.mainHeader}>FUNAAB APPOINTS NEW VICE CHANCELLOR</p>
                                        <p className={classes.under}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
    laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non </p>
                                        <p className={classes.readMore}>Read more...</p>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

            </div>
        </div>
    );
}

export default BioData;
