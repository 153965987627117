import React from "react";
import classes from '../FundAcct/FundAcct.module.css';
// import logo from "../../assets/FUNAAB-Logo 1.png"
// import {Routes, Route} from 'react-router-dom';
// import Login from "./Pages/Login/Login"
// import girlImg from "../../assets/girlImg.png";
import pfp from "../../Assets/pfp.png";
// import pysf from '../../assets/payurfees.png'
// import hostlfees from '../../assets/hostlfees.png'
// import fundurwlt from '../../assets/fundurwlt.png'
// import chekurrslt from '../../assets/chkurwlt.png'
// import gturcrsmtrls from '../../assets/gturcrsmtrls.png'
// import rgstrurcurses from '../../assets/rgstrurcurses.png'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import plus from '../../Assets/plusicon.svg'
import bal from '../../Assets/balicon.svg'
import crediticon from '../../Assets/crediticon.svg'
import debiticon from '../../Assets/debiticon.svg'
import feesicon from '../../Assets/feesicon.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'

function FundAccount() {
    return (
        <div>
              <div className={classes.acctnum}>
                <div>
                <p>Make a bank transfer to your account number 0192348907 select
                    WEMA BANK as the  destination bank when making the transfer.
                    your funds will reflect in your wallet once your bank transfer is
                    complete.</p>
                <button>Copy Account number</button>
                </div>     
                        </div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>Wallet</p>
                    {/* <p className={classes.qlnktxt}>Quicklinks</p> */}
                    {/* <div className={classes.qlnks}>
                    
                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>

                    <div className={classes.frstcolumn}>

                        <div className={classes.frstsubcolumn}>
                            <img src={gturcrsmtrls} className={classes.gturcrsmtrls} />
                            <img src={chekurrslt} className={classes.chekurrslt} />
                        </div>
                        <img src={rgstrurcurses} className={classes.rgstrurcurses} />
                    </div>

                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>
                </div> */}
                
                    <div className={classes.dashBoardDiv}>
                        <div className={classes.balT}>
                        <div className={classes.balTop}>
                            <div className={classes.balSub}>
                                <p className={classes.balance}>BALANCE</p>
                                <h4>N500,000</h4>
                                <button><img src={plus} />Fund</button>
                            </div>
                            
                            <div className={classes.acctBal}>
                                <div className={classes.acctBalsub}>
                                    <p className={classes.acct1}>Account number</p>
                                    <p className={classes.acct2}>0192348907</p>
                                </div>
                                <div>
                                    <img src={bal} />
                                </div>
                            </div>
                        
                        </div>

                        <div className={classes.transHist}>
                            <div>Transaction History</div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>TYPE</th>
                                        <th>AMOUNT</th>
                                        <th>DATE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className={classes.iconbal}><img src={crediticon} />Transfer in from bank</td>
                                        <td>100,000</td>
                                        <td>April 12, 2024 - 02:34am</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.iconbal}><img src={debiticon} />Payment for school fees</td>
                                        <td>100,000</td>
                                        <td>April 12, 2024 - 12:11pm</td>
                                    </tr>
                                </tbody>
                                
                            </table>
                            <p>See more...</p>
                        </div>
                        </div>
                        
                        <div className={classes.balTop2}>
                            <img src={feesicon} />
                            <h4>Pay all your Fees easily</h4>
                            <p>A new way to pay all your fees is here, simply
                            fund your wallet through bank transfer and pay
                            all your fees from your wallet.</p>

                        </div>
                        </div>
                        
                </div>
                

            </div>
        </div>
    );
}

export default FundAccount;
