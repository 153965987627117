import React from "react";
import classes from './Login.module.css';
import loginImg from '../../Assets/loginImg.png';
import logo from "../../Assets/FUNAAB-Logo 1.png"
import { Link, useLocation, useNavigate } from 'react-router-dom'
// import DashboardNav from '../../Components/Navigation.js/Navigation'
// import Horheader from '../../Components/horheader/horheader'
// import classes from '../../Dashboard/Dashboard.module.css'
// import pysf from '../../Assets/payurfees.png'
// import hostlfees from '../../Assets/hostlfees.png'
// import fundurwlt from '../../Assets/fundurwlt.png'
// import chekurrslt from '../../Assets/chkurwlt.png'
// import gturcrsmtrls from '../../Assets/gturcrsmtrls.png'
// import rgstrurcurses from '../../Assets/rgstrurcurses.png'
// import {Routes, Route} from 'react-router-dom';
// import Login from "./Pages/Login/Login"

function Login() {
  return (
    <div className={classes.general}>
        <div className={classes.imgSpace}>
        <img src={loginImg} className={`${classes.LoginImg} ${classes.noScale}`}/>        </div>
        <div className={classes.inputField}>
            <img src={logo} className={classes.logo}/> 
            <h2>Log In to your Student Portal</h2>
            <div className={classes.Inpcontaer}>
                <h4>Log In Portal</h4>
                
                <div className={classes.inputContainer}>
                    <label for="input-field">Matric Number/Registration number</label>
                    <input type="text" placeholder="CSC0248045457"/>
                </div>
                <div className={classes.inputContainer}>
                    <label for="input-field">password</label><br/>
                    <input type="password"/>
                </div>
                <Link to={'/dashboard'}> <button className={classes.loginBtn}>Log In</button></Link>
                
            </div>
        </div>
    </div>
  );
}

export default Login;
