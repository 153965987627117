import React, { useState, useEffect } from 'react';
import logo from '../../Assets/dashboardlogo.png'
import dshbrd from '../../Assets/graph.png'
import dshbrdActive from '../../Assets/graph-active.png'
import reg from '../../Assets/receipt-2.png'
import history from '../../Assets/receipt-2.png'
import regActive from '../../Assets/receipt-2-active.png'
import historyActive from '../../Assets/receipt-2-active.png'
import bdata from '../../Assets/receipt-text.png'
import bdataActive from '../../Assets/receipt-text-active.png'
import elibry from '../../Assets/book.png'
import elibryActive from '../../Assets/book-active.png'
import wallet from '../../Assets/empty-wallet.png'
import walletActive from '../../Assets/empty-wallet-active.png'
import payfee from '../../Assets/card-pos.png'
import payfeeActive from '../../Assets/card-pos-active.png'
import assgnmnt from '../../Assets/note-2.png'
import assgnmntActive from '../../Assets/note-2-active.png'
import clndr from '../../Assets/calendar.png'
import clndrActive from '../../Assets/calendar-active.png'
import buk2 from '../../Assets/book2.png'
import buk2Active from '../../Assets/book2-active.png'
import prntxmps from '../../Assets/receipt-item.png'
import prntxmpsActive from '../../Assets/receipt-item-active.png'
import usrnysc from '../../Assets/user-square.png'
import usrnyscActive from '../../Assets/user-square-active.png'
import login from '../../Assets/login.png'
import classes from './navigation.module.css'
import { Link, useLocation, useNavigate } from 'react-router-dom'



export default function DashboardNav() {
    const [activeItem, setActiveItem] = useState('Dashboard');

    useEffect(() => {
        // Here you can add code to fetch the current URL or any other logic you want to run when the component mounts
        // For simplicity, let's assume the active item is determined based on the URL
        const pathname = window.location.pathname;
        // Logic to set the active item based on the URL
        // For example:
        if (pathname.includes('/registration')) {
            setActiveItem('Registration');
        } else if (pathname.includes('/profile')) {
            setActiveItem('Biodata');
        } else if (pathname.includes('/reg')) {
            setActiveItem('E-Library');
        } 
     else if (pathname.includes('/senate')) {
        setActiveItem('SenateAffairs');
    }
     else if (pathname.includes('/reg')) {
        setActiveItem('History');
    }
     else if (pathname.includes('/wallet')) {
        setActiveItem('Wallet');
    }
     else if (pathname.includes('/school_summary')) {
        setActiveItem('Payschoolfees');
    }
     else if (pathname.includes('/hostel')) {
        setActiveItem('PayHostelfees');
    }
     
     else if (pathname.includes('/lecture_timetable')) {
        setActiveItem('LecturesTimetable');
    }
     else if (pathname.includes('/exam_timetable')) {
        setActiveItem('ExamTimetable');
    }
     else if (pathname.includes('/check_result')) {
        setActiveItem('Checkresult');
    }
     else if (pathname.includes('/reg')) {
        setActiveItem('ExamPass');
    }
     else if (pathname.includes('/reg')) {
        setActiveItem('Clearance');
    }
     else if (pathname.includes('/reg')) {
        setActiveItem('Premobilization');
    }
    else if (pathname.includes('/transactionHry')) {
        setActiveItem('History');
    }
    else if (pathname.includes('/assign')) {
        setActiveItem('Assignments');
    }
    else if (pathname.includes('/coursemat')) {
        setActiveItem('CourseMaterials');
    }
    else if (pathname.includes('/exam_pass')) {
        setActiveItem('ExamPass');
    }
    else if (pathname.includes('/hostel')) {
        setActiveItem('PayHostelfees');
    }
    else if (pathname.includes('/other_fees')) {
        setActiveItem('Otherfees');
    }
    }, []);

    const handleItemClick = (itemName) => {
        setActiveItem(itemName);
        // You can add additional logic here if needed
    };


    const getIcon = (itemName, defaultIcon, activeIcon) => {
        return activeItem === itemName ? activeIcon : defaultIcon;
    };


    return (

        <div>
            <div className={classes.navbrd}>
                <div className={classes.navdshbrd}>
                    <div className={classes.logodiv}>
                        <img src={logo} className={classes.logo} />
                    </div>

                    <div className={classes.gnrlcls}>
                        <p className={classes.gnrltxt}>GENERAL</p>
                        <Link
                        to={'/dashboard'}
                            className={`${classes.thetxt} ${classes.sidebaritem} ${activeItem === 'Dashboard' ? classes.active : ''}`} onClick={() => handleItemClick('Dashboard')}>
                            <img src={getIcon('Dashboard', dshbrd, dshbrdActive)} 
                                className={classes.icondshbrd}   />
                            <p className={classes.dshbrdtxt}>Dashboard</p>
                            
                        </Link>
                        <Link
                        to={'/registration'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'Registration' ? classes.active : ''}`} onClick={() => handleItemClick('Registration')}>
                            <img src={getIcon('Registration', reg, regActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Registration</p>
                        </Link>
                        <Link
                        to={'/profile'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'Biodata' ? classes.active : ''}`} onClick={() => handleItemClick('Biodata')}>
                            <img src={getIcon('Biodata', bdata, bdataActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Biodata</p>
                        </Link>
                        <Link
                        to={'/reg'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'E-Library' ? classes.active : ''}`} onClick={() => handleItemClick('E-Library')}>
                            <img src={getIcon('E-Library', elibry, elibryActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>E-Library</p>
                        </Link>
                        <Link
                        to={'/senate'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'SenateAffairs' ? classes.active : ''}`} onClick={() => handleItemClick('SenateAffairs')}>
                            <img src={getIcon('SenateAffairs', reg, regActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Senate Affairs</p>
                        </Link>

                    </div>

                    <div className={classes.gnrlcls}>
                        <p className={classes.gnrltxt}>PAYMENT</p>
                        <Link
                        to={'/wallet'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'Wallet' ? classes.active : ''}`} onClick={() => handleItemClick('Wallet')}>
                            <img src={getIcon('Wallet', wallet, walletActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Wallet</p>
                        </Link>
                        <Link
                        to={'/school_summary'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'Payschoolfees' ? classes.active : ''}`} onClick={() => handleItemClick('Payschoolfees')}>
                            <img src={getIcon('Payschoolfees', payfee, payfeeActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Pay your school fees</p>
                        </Link>
                        <Link
                        to={'/hostel'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'PayHostelfees' ? classes.active : ''}`} onClick={() => handleItemClick('PayHostelfees')}>
                            <img src={getIcon('PayHostelfees', payfee, payfeeActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Pay your Hostel fees</p>
                        </Link>
                        <Link
                        to={'/transactionHry'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'History' ? classes.active : ''}`} onClick={() => handleItemClick('History')}>
                            <img src={getIcon('History', history, historyActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Transaction History</p>
                        </Link>
                        <Link
                        to={'/other_fees'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'Otherfees' ? classes.active : ''}`} onClick={() => handleItemClick('Otherfees')}>
                            <img src={getIcon('Otherfees', payfee, payfeeActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Other Fees</p>
                        </Link>
                    </div>

                    <div className={classes.gnrlcls}>
                        <p className={classes.gnrltxt}>ACADEMICS</p>
                        <Link
                        to={'/coursemat'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'CourseMaterials' ? classes.active : ''}`} onClick={() => handleItemClick('CourseMaterials')}>
                            <img src={getIcon('CourseMaterials', elibry, elibryActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Course Materials</p>
                        </Link>
                        <Link
                        to={'/assign'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'Assignments' ? classes.active : ''}`} onClick={() => handleItemClick('Assignments')}>
                            <img src={getIcon('Assignments', assgnmnt, assgnmntActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Assignments</p>
                        </Link>
                        <Link
                        to={'/lecture_timetable'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'LecturesTimetable' ? classes.active : ''}`} onClick={() => handleItemClick('LecturesTimetable')}>
                            <img src={getIcon('LecturesTimetable', clndr, clndrActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Lectures Timetable</p>
                        </Link>
                        <Link
                        to={'/exam_timetable'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'ExamTimetable' ? classes.active : ''}`} onClick={() => handleItemClick('ExamTimetable')}>
                             <img src={getIcon('ExamTimetable', clndr, clndrActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Exam Timetable</p>
                        </Link>
                        <Link
                        to={'/check_result'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'Checkresult' ? classes.active : ''}`} onClick={() => handleItemClick('Checkresult')}>
                            <img src={getIcon('Checkresult', buk2, buk2Active)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Check your result</p>
                        </Link>
                        <Link
                        to={'/exam_pass'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'ExamPass' ? classes.active : ''}`} onClick={() => handleItemClick('ExamPass')}>
                             <img src={getIcon('ExamPass', prntxmps, prntxmpsActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Print Exam Pass</p>
                        </Link>

                    </div>

                    <div className={classes.gnrlcls}>
                        <p className={classes.gnrltxt}>FINAL YEAR</p>
                        <Link
                        to={'/reg'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'Clearance' ? classes.active : ''}`} onClick={() => handleItemClick('Clearance')}>
                            <img src={getIcon('Clearance', buk2, buk2Active)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Final Clearance</p>
                        </Link>
                        <Link
                        to={'#'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'Premobilization' ? classes.active : ''}`} onClick={() => handleItemClick('Premobilization')}>
                            <img src={getIcon('Premobilization', usrnysc, usrnyscActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>NYSC Premobilization</p>
                        </Link>


                    </div>
                    
                    <div className={classes.gnrlcls}>
                        <div className={classes.thetxtlgot}>
                            <Link 
                            to={'/login'} className={classes.logoutDis}>
                                <div className={classes.lgtupd}>
                                    <img src={login} className={classes.icondshbrd} />
                                    <p className={classes.dshbrdtxt}>Logout</p>
                                </div>
                            </Link>
                            

                        </div>
                    </div>
                </div>

            </div>
        </div>

    )
}

