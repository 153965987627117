import React from "react";
import classes from '../New Assignment/NewAssignment.module.css';
// import logo from "../../assets/FUNAAB-Logo 1.png"
// import {Routes, Route} from 'react-router-dom';
// import Login from "./Pages/Login/Login"
// import girlImg from "../../assets/girlImg.png";
import pfp from "../../Assets/pfp.png";
// import pysf from '../../assets/payurfees.png'
// import hostlfees from '../../assets/hostlfees.png'
// import fundurwlt from '../../assets/fundurwlt.png'
// import chekurrslt from '../../assets/chkurwlt.png'
// import gturcrsmtrls from '../../assets/gturcrsmtrls.png'
// import rgstrurcurses from '../../assets/rgstrurcurses.png'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import back from '../../Assets/backicon.svg'
import bookicon from '../../Assets/bookicon.svg'
import book from '../../Assets/book.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'

function NewAssignment() {
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>Assignments</p>
                    {/* <p className={classes.qlnktxt}>Quicklinks</p> */}
                    {/* <div className={classes.qlnks}>
                    
                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>

                    <div className={classes.frstcolumn}>

                        <div className={classes.frstsubcolumn}>
                            <img src={gturcrsmtrls} className={classes.gturcrsmtrls} />
                            <img src={chekurrslt} className={classes.chekurrslt} />
                        </div>
                        <img src={rgstrurcurses} className={classes.rgstrurcurses} />
                    </div>

                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>
                </div> */}
                    <div className={classes.dashBoardDiv}>
                        <div className={classes.subContainer}>
                            <img src={back} />
                            <p>Assignments</p>
                        </div>
                        <div className={classes.container}>
                            <h4 className={classes.newassign}>New Assignments</h4>
                            <div className={classes.assignments}>

                                <div className={classes.assignmentcard}>
                                <Link to={'/submitassign'}><img src={book} alt="Icon" className={classes.icon} /></Link>
                                    <div className={classes.assignmentinfo}>
                                    <Link to={'/submitassign'}><h4>Principles of debugging</h4></Link>
                                    <Link to={'/submitassign'}><p>This assignment will help you understand the...</p></Link>
                                        <Link to={'/submitassign'}><a href="#">View Assignment</a></Link>
                                        <span className={classes.date}>Uploaded on 8th April, 2024 - 09:45am</span>
                                    </div>
                                </div>
                                <div className={classes.assignmentcard}>
                                <Link to={'/submitassign'}><img src={book} alt="Icon" className={classes.icon} /></Link>
                                    <div className={classes.assignmentinfo}>
                                    <Link to={'/submitassign'}><h4>Principles of debugging</h4></Link>
                                        <Link to={'/submitassign'}> <p>This assignment will help you understand the...</p></Link>
                                        <Link to={'/submitassign'}><a href="#">View Assignment</a></Link>
                                        <span className={classes.date}>Uploaded on 8th April, 2024 - 09:45am</span>
                                    </div>
                                </div>
                            </div>

                            <h4 className={classes.olderassign}>Older Assignments</h4>
                            <div className={classes.assignments}>

                                <div className={classes.assignmentcard}>
                                <Link to={'/submitassign'}><img src={book} alt="Icon" className={classes.icon} /></Link>
                                    <div className={classes.assignmentinfo}>
                                    <Link to={'/submitassign'}><h4>5 Computer language models</h4></Link>
                                        <Link to={'/submitassign'}><p>This assignment will help you understand the...</p></Link>
                                        <Link to={'/submitassign'}><a href="#">View Assignment</a></Link>
                                        <span className={classes.date}>Uploaded on 15th April, 2024 - 09:45am</span>
                                    </div>
                                </div>
                                <div className={classes.assignmentcard}>
                                <Link to={'/submitassign'}><img src={book} alt="Icon" className={classes.icon} /></Link>
                                    <div className={classes.assignmentinfo}>
                                    <Link to={'/submitassign'}><h4>5 Computer language models II</h4></Link>
                                        <Link to={'/submitassign'}><p>This assignment will help you understand the...</p></Link>
                                        <Link to={'/submitassign'}><a href="#">View Assignment</a></Link>
                                        <span className={classes.date}>Uploaded on 15th April, 2024 - 09:45am</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>

    );
}

export default NewAssignment;
