import React from "react";
import classes from './ExamTimetableLV.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation';
import Horheader from '../../Components/horheader/horheader';
import ExamView from '../../Components/Exam View2/ExamView';
import timeIcon from '../../Assets/timeIcon.png';
import locationIcon from '../../Assets/location.png'

const ListView = () => {


  return (
    <div className={classes.appcontainer}>
      <div className={classes.sidenav}>
        <DashboardNav className={classes.scrollable} />
      </div>

      <div className={classes.maincontent}>
        <Horheader />
        <p className={classes.dshbrdtxt}>Exam Timetable</p>

        <div className={classes.dashBoardDiv}>
          <ExamView />
          <div className={classes.sldk}>
            <div className={classes.lvTable}>
              <div className={classes.lvTableMain}>
                  <div className={classes.dateRow}>
                  <p>Thur<br/><span>May 4th</span></p>
                  </div>
                  <div className={classes.verLine}/>

                  <div className={classes.timeIconContainer}>
                      <div className={classes.iconTxt}>
                      <img src={timeIcon}/>
                      <p>8:00am - 10:00am</p>
                  </div>
                  <div className={classes.iconTxt}>
                      <img src={locationIcon}/>
                      <p>8:00am - 10:00am</p>
                  </div>
                  </div>

                  <div className={classes.courseInfo}>
                      <p className={classes.hfh4}>CSC 201</p>
                      <p>Computer programming and debugging</p>
                  </div>
              </div>
            </div>
            <div className={classes.lvTable2}>
              <div className={classes.lvTableMain}>
                  <div className={classes.dateRow}>
                  <p>Thur<br/><span>May 4th</span></p>
                  </div>
                  <div className={classes.verLine}/>

                  <div className={classes.timeIconContainer}>
                      <div className={classes.iconTxt}>
                      <img src={timeIcon}/>
                      <p>8:00am - 10:00am</p>
                  </div>
                  <div className={classes.iconTxt}>
                      <img src={locationIcon}/>
                      <p>8:00am - 10:00am</p>
                  </div>
                  </div>

                  <div className={classes.courseInfo}>
                      <p className={classes.hfh4}>CSC 201</p>
                      <p>Computer programming and debugging</p>
                  </div>
              </div>
            </div>
          </div>

        </div>

      </div>

    </div>
  );
};

export default ListView;
