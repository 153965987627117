import React from "react";
import classes from './ExamPass.module.css';
// import logo from "../../assets/FUNAAB-Logo 1.png"
// import {Routes, Route} from 'react-router-dom';
// import Login from "./Pages/Login/Login"
// import girlImg from "../../assets/girlImg.png";
import pfp from "../../Assets/pfp.png";
// import pysf from '../../assets/payurfees.png'
// import hostlfees from '../../assets/hostlfees.png'
// import fundurwlt from '../../assets/fundurwlt.png'
// import chekurrslt from '../../assets/chkurwlt.png'
// import gturcrsmtrls from '../../assets/gturcrsmtrls.png'
// import rgstrurcurses from '../../assets/rgstrurcurses.png'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import book from '../../Assets/book.png'
import imageeee from '../../Assets/imageeeee.jpg'
import flogos from '../../Assets/flogos.png'
import { Link, useLocation, useNavigate } from 'react-router-dom'


function ExamPass() {
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>Print Exam Pass</p>
                    {/* <p className={classes.qlnktxt}>Quicklinks</p> */}
                    {/* <div className={classes.qlnks}>  
                    
                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>

                    <div className={classes.frstcolumn}>

                        <div className={classes.frstsubcolumn}>
                            <img src={gturcrsmtrls} className={classes.gturcrsmtrls} />
                            <img src={chekurrslt} className={classes.chekurrslt} />
                        </div>
                        <img src={rgstrurcurses} className={classes.rgstrurcurses} />
                    </div>

                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>
                </div> */}
                    <div className={classes.dashBoardDiv}>
                        <p>Exam Pass for 100 level students for first semester</p>
                        <div className={classes.firstdiv}>
                            <div className={classes.flex1}>
                                <img src={imageeee} />
                            </div>
                            <div className={classes.flex2}>
                                <img src={flogos} />
                                <h2>FEDERAL UNIVERSITY OF AGRICULTURE, ABEOKUTA</h2>
                                <div className={classes.contents}>
                                    <div className={classes.contents1}>
                                        <p>Name</p>
                                        <p>Matric No.</p>
                                        <p>Faculty:</p>
                                        <p>Department:</p>
                                        <p>Level:</p>
                                    </div>
                                    <div className={classes.contents2}>
                                        <p>Amaka Anderson</p>
                                        <p>CSC0348AADFD</p>
                                        <p>Faculty of Science</p>
                                        <p>Department of Computer Science</p>
                                        <p>100Level</p>
                                    </div>
                                </div>
                                {/* <table>
                                    <tr>
                                        <td>Name</td>
                                        <td>Maria Anders</td>
                                    </tr>
                                    <tr>
                                        <td>Matric No.</td>
                                        <td>Francisco Chang</td>
                                    </tr>
                                    <tr>
                                        <td>Matric No.</td>
                                        <td>Francisco Chang</td>
                                    </tr>
                                    <tr>
                                        <td>Matric No.</td>
                                        <td>Francisco Chang</td>
                                    </tr>
                                    <tr>
                                        <td>Matric No.</td>
                                        <td>Francisco Chang</td>
                                    </tr>
                                    <tr>
                                        <td>Matric No.</td>
                                        <td>Francisco Chang</td>
                                    </tr>
                                </table> */}
                                <b>EXAM PASS FOR 2023/2024 100 LEVEL CBT EXAMINATION</b>
                            </div>
                        </div>
                        
                        <div className={classes.dashBoardDiv1}>
                        <button>
                            <Link to={'/exampass2'}>Print Exam Pass</Link>
                        </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default ExamPass;
