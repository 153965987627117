import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './ExamVIew.module.css';

const ButtonToggle = () => {
  const [selectedView, setSelectedView] = useState('calendar');
  const [route, setRoute] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (route) {
      navigate(route);
    }
  }, [route, navigate]);

  const handleClick = (view, route) => {
    setSelectedView(view);
    setRoute(route); // Set route for delayed navigation
  };

  return (
    <div>
      <p className={classes.head}>Exam Timetable for 200 level Computer Science </p>
      <div className={classes.buttonToggle}>
        <button
          className={`${classes.toggleButton} ${selectedView === 'calendar' ? classes.selected : ''}`}
          onClick={() => handleClick('calendar', '/exam_timetable')}
        >
          Calendar View
        </button>
        <button
          className={`${classes.toggleButton} ${selectedView === 'list' ? classes.selected : ''}`}
          onClick={() => handleClick('list', '/exam_timetable_lv')}
        >
          List View
        </button>
      </div>
    </div>
  );
};

export default ButtonToggle;
