import React, { useState } from "react";
import classes from './LectureTimetable.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation';
import Horheader from '../../Components/horheader/horheader';
import CalenderView from '../../Components/Calender View/CalenderVIew';


const Timetable = () => {
    const [showContainer, setShowContainer] = useState(false);

  return (
    <div className={classes.appcontainer}>
      <div className={classes.sidenav}>
        <DashboardNav className={classes.scrollable} />
      </div>

      <div className={classes.maincontent}>
        <Horheader />
        <p className={classes.dshbrdtxt}>Lectures Timetable</p>

        <div className={classes.dashBoardDiv}>
          <CalenderView />

          
          <div className={classes.timetableContainer}>
    <table className={classes.Timetable}>
        <thead className={classes.theReal}>
            <tr>
                <th></th>
                <th>Mon</th>
                <th>Tue</th>
                <th>Wed</th>
                <th>Thu</th>
                <th>Fri</th>
                <th>Sat</th>
                <th>Sun</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td className={classes.time}>08:00am</td>
                <td className={classes.cellWithBord} rowSpan="2">
                    <div className={classes.schedule}>
                        <div className={classes.verLine}/>
                        <div className={classes.txtInScdle}>
                        <p className={classes.headerInScdle}>CSC 201</p>
                        <p className={classes.course}>Computer programming and<br/>debugging</p>
                        <p className={classes.classTime}>8:00am - 9:00am</p>
                        <button className={classes.clLink}>Class link</button>
                        {/* <small>Computer programming and<br/>debugging</small> */}
                        </div>
                    </div>
                </td>
                {/* <td className={classes.cell}></td> */}
                <td className={classes.cell}></td>
                <td className={classes.cellWithBord} rowSpan="2">
                    <div className={classes.schedule}>
                        <div className={classes.verLine}/>
                        <div className={classes.txtInScdle}>
                        <p className={classes.headerInScdle}>CSC 205</p>
                        <p className={classes.course}>Computer hardware and<br/>networking systems</p>
                        <p className={classes.classTime}>8:00am - 9:00am</p>
                        <button onClick={() => setShowContainer(!showContainer)}
                        className={classes.clLink}>Class link</button>
                        {showContainer && <div className={classes.greenshit}>
                            <h4>CSC 205</h4>
                            <small>Computer hardware and networking<br/>
systems</small>
                            <h4>LECTURER</h4>
                            <small>Dr Owonikoko Alade</small>
                            <p>8:00am - 9:00am</p>
                            <small>LINK TO CLASS</small>
                            <small>bngr-ynyr-evwr</small>
                            </div>}
                        {/* <small>Computer programming and<br/>debugging</small> */}
                        </div>
                    </div>
                </td>
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
            </tr>
            <tr>
                <td className={classes.time}>09:00am</td>
                {/* <td className={classes.cell}></td> */}
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
                {/* <td className={classes.cell}></td> */}
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
            </tr>
            <tr>
                <td className={classes.time}>10:00am</td>
                {/* <td className={classes.cell}></td> */}
                <td className={classes.cell}></td>
                <td className={classes.cellWithBord} rowSpan="2">
                    <div className={classes.schedule}>
                        <div className={classes.verLine}/>
                        <div className={classes.txtInScdle}>
                        <p className={classes.headerInScdle}>CSC 203</p>
                        <p className={classes.course}>Computer language and<br/>
syntaxes</p>
                        <p className={classes.classTime}>10:00am - 11:00am</p>
                        <button className={classes.clLink}>Class link</button>
                        {/* <small>Computer programming and<br/>debugging</small> */}
                        </div>
                    </div>
                </td>
                <td className={classes.cell}></td>
                {/* <td className={classes.cell}></td> */}
                <td className={classes.cellWithBord} rowSpan="2">
                    <div className={classes.schedule}>
                        <div className={classes.verLine}/>
                        <div className={classes.txtInScdle}>
                        <p className={classes.headerInScdle}>CSC 201</p>
                        <p className={classes.course}>Introduction to computer<br/>
language models</p>
                        <p className={classes.classTime}>10:00am - 11:00am</p>
                        <button className={classes.clLink}>Class link</button>
                        {/* <small>Computer programming and<br/>debugging</small> */}
                        </div>
                    </div>
                </td>
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
            </tr>
            <tr>
                <td className={classes.time}>11:00am</td>
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
                {/* <td className={classes.cell}></td>
                <td className={classes.cell}></td> */}
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
            </tr>
            <tr>
                <td className={classes.time}>12:00pm</td>
                <td className={classes.cellWithBord} rowSpan="2">
                    <div className={classes.schedule}>
                        <div className={classes.verLine}/>
                        <div className={classes.txtInScdle}>
                        <p className={classes.headerInScdle}>MTS 201</p>
                        <p className={classes.course}>Mathematics Logic and<br/>
Syntaxes</p>
                        <p className={classes.classTime}>12:00am - 1:00am</p>
                        <button className={classes.clLink}>Class link</button>
                        {/* <small>Computer programming and<br/>debugging</small> */}
                        </div>
                    </div>
                </td>
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
                 <td className={classes.cellWithBord} rowSpan="2">
                    <div className={classes.schedule}>
                        <div className={classes.verLine}/>
                        <div className={classes.txtInScdle}>
                        <p className={classes.headerInScdle}>CSC 207</p>
                        <p className={classes.course}>Computer history II<br/></p>
                        <p className={classes.classTime}>12:00am - 1:00am</p>
                        <button className={classes.clLink}>Class link</button>
                        {/* <small>Computer programming and<br/>debugging</small> */}
                        </div>
                    </div>
                </td>
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
            </tr>
            <tr>
                <td className={classes.time}>01:00pm</td>
                <td className={classes.cell}></td>
                {/* <td className={classes.cell}></td>
                <td className={classes.cell}></td> */}
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
            </tr>
            <tr>
                <td className={classes.time}>2:00pm</td>
                <td className={classes.cellWithBord} rowSpan="2">
                    <div className={classes.schedule}>
                        <div className={classes.verLine}/>
                        <div className={classes.txtInScdle}>
                        <p className={classes.headerInScdle}>GNS 201</p>
                        <p className={classes.course}>Philosophy and Philosophical<br/>
models</p>
                        <p className={classes.classTime}>2:00am - 3:00am</p>
                        <button className={classes.clLink}>Class link</button>
                        {/* <small>Computer programming and<br/>debugging</small> */}
                        </div>
                    </div>
                </td>
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
                <td className={classes.cellWithBord} rowSpan="2">
                    <div className={classes.schedule}>
                        <div className={classes.verLine}/>
                        <div className={classes.txtInScdle}>
                        <p className={classes.headerInScdle}>PHY 207</p>
                        <p className={classes.course}>Introduction to quantum<br/>
Physics</p>
                        <p className={classes.classTime}>2:00am - 3:00am</p>
                        <button className={classes.clLink}>Class link</button>
                        {/* <small>Computer programming and<br/>debugging</small> */}
                        </div>
                    </div>
                </td>
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
            </tr>
            <tr>
                <td className={classes.time}>03:00pm</td>
                <td className={classes.cellWithBord} rowSpan="2">
                    <div className={classes.schedule}>
                        <div className={classes.verLine}/>
                        <div className={classes.txtInScdle}>
                        <p className={classes.headerInScdle}>MTS 203</p>
                        <p className={classes.course}>Mathematical Language<br/>
models</p>
                        <p className={classes.classTime}>3:00am - 4:00am</p>
                        <button className={classes.clLink}>Class link</button>
                        {/* <small>Computer programming and<br/>debugging</small> */}
                        </div>
                    </div>
                </td>
                {/* <td className={classes.cell}></td>
                <td className={classes.cell}></td> */}
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
            </tr>
            <tr>
                <td className={classes.time}>04:00pm</td>
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
                {/* <td className={classes.cell}></td> */}
            </tr>
            <tr>
                <td className={classes.time}>05:00pm</td>
                {/* <td className={classes.cell} rowSpan="2">jejjje</td> */}
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
                {/* <td className={classes.cell}></td> */}
                <td className={classes.cellWithBord} rowSpan="2">
                    <div className={classes.schedule}>
                        <div className={classes.verLine}/>
                        <div className={classes.txtInScdle}>
                        <p className={classes.headerInScdle}>CSC 201</p>
                        <p className={classes.course}>Computer programming and<br/>debugging</p>
                        <p className={classes.classTime}>5:00am - 6:00am</p>
                        <button className={classes.clLink}>Class link</button>
                        {/* <small>Computer programming and<br/>debugging</small> */}
                        </div>
                    </div>
                </td>
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
            </tr>
            <tr>
                <td className={classes.time}>06:00pm</td>
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
                {/* <td className={classes.cell}></td> */}
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
                <td className={classes.cell}></td>
            </tr>
        </tbody>
    </table>
</div>

          

        </div>

      </div>

    </div>
  );
};

export default Timetable;
