import React from "react";
import classes from './Registration.module.css';
// import logo from "../../assets/FUNAAB-Logo 1.png"
// import {Routes, Route} from 'react-router-dom';
// import Login from "./Pages/Login/Login"
// import girlImg from "../../Assets/girlImg.png";
// import pfp from "../../Assets/pfp.png";
// import pysf from '../../Assets/payurfees.png'
// import hostlfees from '../../Assets/hostlfees.png'
// import fundurwlt from '../../Assets/fundurwlt.png'
// import chekurrslt from '../../Assets/chkurwlt.png'
// import gturcrsmtrls from '../../Assets/gturcrsmtrls.png'
// import rgstrurcurses from '../../Assets/rgstrurcurses.png'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import { Link, useLocation, useNavigate } from 'react-router-dom'

function Registration() {
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>Registration</p>
                    {/* <p className={classes.qlnktxt}>Quicklinks</p> */}
                    {/* <div className={classes.qlnks}>
                    
                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>

                    <div className={classes.frstcolumn}>

                        <div className={classes.frstsubcolumn}>
                            <img src={gturcrsmtrls} className={classes.gturcrsmtrls} />
                            <img src={chekurrslt} className={classes.chekurrslt} />
                        </div>
                        <img src={rgstrurcurses} className={classes.rgstrurcurses} />
                    </div>

                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>
                </div> */}
                    <div className={classes.dashBoardDiv}>
                        <div>
                            <p className={classes.selctTxt}>Select the courses you wish to register for your 200 level first semester here</p>
                            <div className={classes.cusTable}>
                                <table className={classes.customTable}>
                                    <thead>
                                        <tr>
                                            <th>Select</th>
                                            <th>Course Code</th>
                                            <th>Course description</th>
                                            <th>Course Unit</th>
                                            <th>Course Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><input type="checkbox" name="selectRow" id="radio1" /><label htmlFor="radio1"></label></td>
                                            <td>cosc 201</td>
                                            <td>computer programming and debugging</td>
                                            <td>3</td>
                                            <td>Required</td>
                                        </tr>
                                        <tr>
                                            <td><input type="checkbox" name="selectRow" id="radio2" /><label htmlFor="radio2"></label></td>
                                            <td>cosc 202</td>
                                            <td>computer language and syntax</td>
                                            <td>2</td>
                                            <td>Required</td>
                                        </tr>
                                        {/* Add more rows as needed */}
                                    </tbody>
                                </table>
                            </div>



                        </div>

                        <div className={classes.nextTable}>
                            <p className={classes.selctTxt}>Carryover courses</p>
                            <div className={classes.cusTable}>
                                <table className={classes.customTable}>
                                    <thead>
                                        <tr>
                                            <th>Select</th>
                                            <th>Course Code</th>
                                            <th>Course description</th>
                                            <th>Course Unit</th>
                                            <th>Course Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><input type="checkbox" name="selectRow" id="radio3" /><label htmlFor="radio3"></label></td>
                                            <td>cosc 201</td>
                                            <td>computer programming and debugging</td>
                                            <td>3</td>
                                            <td>Required</td>
                                        </tr>
                                        <tr>
                                            <td><input type="checkbox" name="selectRow" id="radio4" /><label htmlFor="radio4"></label></td>
                                            <td>cosc 202</td>
                                            <td>computer language and syntax</td>
                                            <td>2</td>
                                            <td>Required</td>
                                        </tr>
                                        {/* Add more rows as needed */}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <p className={classes.note}>NOTE</p>
                        <p className={classes.notice}>You can only register for 21 units of courses this semester</p>
                        <Link to={'/registration2'}>
                            <button className={classes.regCo}>
                                Register your courses
                            </button>
                        </Link>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Registration;
