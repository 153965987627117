import React from "react";
import classes from './OtherFees.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import { Link, useLocation, useNavigate } from 'react-router-dom'

function Registration() {
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>Other Fees</p>

                    <div className={classes.dashBoardDiv}>
                        <div className={classes.otherFees}>
                            <div className={classes.cont1}>
                                <div>
                                    <h3>Field Trip for 200 level students</h3>
                                    <p className={classes.real}>Field trip for all 200 level computer science students to NIITDA data centre in Abuja,<br/>
                                    this trip is important for all 200 level computer science students</p>
                                </div>
                                <div className={classes.buttondsg}>
                                    <Link to={'/make_payment_other'}>
                                    <button>
                                        Pay now
                                    </button>
                                    </Link>
                                    
                                </div>
                            </div>
                            <div className={classes.cont1}>
                                <div>
                                    <h3>Field Trip for 200 level students</h3>
                                    <p className={classes.real}>Field trip for all 200 level computer science students to NIITDA data centre in Abuja,<br/>
                                    this trip is important for all 200 level computer science students</p>
                                </div>
                                <div className={classes.buttondsg}>
                                    <button>
                                        Pay now
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className={classes.otherFees}>
                            <div className={classes.cont1}>
                                <div>
                                    <h3>Field Trip for 200 level students</h3>
                                    <p className={classes.real}>Field trip for all 200 level computer science students to NIITDA data centre in Abuja,<br/>
                                    this trip is important for all 200 level computer science students</p>
                                </div>
                                <div className={classes.buttondsg}>
                                    <button>
                                        Pay now
                                    </button>
                                </div>
                            </div>
                            <div className={classes.cont1}>
                                <div>
                                    <h3>Field Trip for 200 level students</h3>
                                    <p className={classes.real}>Field trip for all 200 level computer science students to NIITDA data centre in Abuja,<br/>
                                    this trip is important for all 200 level computer science students</p>
                                </div>
                                <div className={classes.buttondsg}>
                                    <button>
                                        Pay now
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className={classes.otherFees}>
                            <div className={classes.cont1}>
                                <div>
                                    <h3>Field Trip for 200 level students</h3>
                                    <p className={classes.real}>Field trip for all 200 level computer science students to NIITDA data centre in Abuja,<br/>
                                    this trip is important for all 200 level computer science students</p>
                                </div>
                                <div className={classes.buttondsg}>
                                    <button>
                                        Pay now
                                    </button>
                                </div>
                            </div>
                            <div className={classes.cont1}>
                                <div>
                                    <h3>Field Trip for 200 level students</h3>
                                    <p className={classes.real}>Field trip for all 200 level computer science students to NIITDA data centre in Abuja,<br/>
                                    this trip is important for all 200 level computer science students</p>
                                </div>
                                <div className={classes.buttondsg}>
                                    <button>
                                        Pay now
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Registration;
