import React from "react";
import classes from '../HostelFeesSum/HostelFeesSum.module.css';
// import logo from "../../assets/FUNAAB-Logo 1.png"
// import {Routes, Route} from 'react-router-dom';
// import Login from "./Pages/Login/Login"
// import girlImg from "../../assets/girlImg.png";
import pfp from "../../Assets/pfp.png";
// import pysf from '../../assets/payurfees.png'
// import hostlfees from '../../assets/hostlfees.png'
// import fundurwlt from '../../assets/fundurwlt.png'
// import chekurrslt from '../../assets/chkurwlt.png'
// import gturcrsmtrls from '../../assets/gturcrsmtrls.png'
// import rgstrurcurses from '../../assets/rgstrurcurses.png'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import htelicon from '../../Assets/undraw_coming_home_re_ausc 1.png'
import back from '../../Assets/backicon.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'

function HostelFeesSum() {
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>Hostel Fees</p>
                    {/* <p className={classes.qlnktxt}>Quicklinks</p> */}
                    {/* <div className={classes.qlnks}>
                    
                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>

                    <div className={classes.frstcolumn}>

                        <div className={classes.frstsubcolumn}>
                            <img src={gturcrsmtrls} className={classes.gturcrsmtrls} />
                            <img src={chekurrslt} className={classes.chekurrslt} />
                        </div>
                        <img src={rgstrurcurses} className={classes.rgstrurcurses} />
                    </div>

                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>
                </div> */}
                    <div className={classes.dashBoardDiv}>
                        <div className={classes.container}>
                            <div className={classes.subContainer}>
                                <img src={back} />
                                <p>Hostel selection</p>
                            </div>

                            <div className={classes.summary}>
                                <h5>Hostel Fees Summary</h5>
                                <p>Hostel fees for Hostel A</p>
                                <table  className={classes.tablebkr}>
                                    <thead>
                                        <tr>
                                            <th>Breakdown</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody className={classes.tablebk}>
                                        <tr>
                                            <td>Bedspace</td>
                                            <td>30,000</td>
                                        </tr>
                                        <tr>
                                            <td>Internet fees</td>
                                            <td>2,000</td>
                                        </tr>
                                        <tr>
                                            <td>Security fees</td>
                                            <td>1,000</td>
                                        </tr>
                                        <tr>
                                            <td>Department fees</td>
                                            <td>2,000</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className={classes.note}>
                                    <h5>Note</h5>
                                    <p >Please ensure you pay your hostel fees on time, because bed space in hostels are allocated on first come, first serve basis.</p>
                                </div>

                            </div>
                        </div>
                        <div className={classes.total} >
                            <div className={classes.subtotal}>
                                <p>Total to be Paid</p>
                                <span className={classes.totalamount}>N50,000</span>
                                <Link to={'/hostelpay'}><button className={classes.paymentbutton}>Make Payment</button></Link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default HostelFeesSum;
