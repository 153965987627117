import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './Dashboard/Dashboard';
import DashboardNav from './Components/Navigation.js/Navigation';
import Login from "./Pages/Login/Login";
import Reg from "./Pages/Reg/Reg";
import BioData from "./Pages/BioData/BioData"
import Registration from "./Pages/Registration1/Registration"
import Registration2 from "./Pages/Registration2/Registration2"
import Profile from "./Pages/Profile/Profile"
import Wallet from "./Pages/Wallet/Wallet"
import VirtualWallet from './Pages/VirtualWallet/VirtualWallet';
import WalletBalance from './Pages/WalletBalance/WalletBalance';
import FundAccount from './Pages/FundAcct/FundAcct';
import Senate from './Pages/Senate Aff/Senate'
import Senate2 from './Pages/Senate Aff2/Senate2'
import PaidFee from './Pages/Paid SchFee/PaidFee'
import SchoolFees2 from './Pages/School Summary/SchoolSummary'
import MakePayment from './Pages/Make Payment/MakePayment'
import MakePaymentOther from './Pages/Make Payment Others/MakePaymentOther'
import NoFees from './Pages/NoFee/NoFees'
import Hostel from './Pages/Hostel/Hostel';
import HostelRoom from './Pages/HostelRoom/HostelRoom';
import HostelFeesSum from './Pages/HostelFeesSum/HostelFeesSum';
import HostelPay from './Pages/HostelPay/HostelPay';
import TransactionHry from './Pages/Transaction History/TransactionHtry'
import TransHtrNo from './Pages/Transaction History Null/TransHtrNo'
import OtherFees from './Pages/Other Fees/OtherFees'
import HostelReceipt from './Pages/HostelReceipt/HostelReceipt';
import HostelAllocation from './Pages/HostelAllocation/HostelAllocation';
import HostelConfirm from './Pages/HostelConfirm/HostelConfirm';
import CourseMaterial from './Pages/CourseMaterial/CourseMaterial';
import CourseMaterial2 from './Pages/CourseMaterial2/CourseMaterial2';
import Assignment from './Pages/Assignment/Assignment';
import NewAssignment from './Pages/New Assignment/NewAssignment';
import SubmitAssignment from './Pages/SubmitAssignment/SubmitAssignment';
import UploadAssignment from './Pages/UploadAssignment/UploadAssignment';
import AssignmentSubmit from './Pages/AssignmentSubmit/AssignmentSubmit';
import OtherSummary from './Pages/Other Fees Summary/OtherSummary'
import OtherFeesPaid from './Pages/Other Fees Paid/OtherFeePaid'
import LectureTimetable from './Pages/Lecture Timetable/LectureTimetable'
import LectureTimetableLV from './Pages/Lecture Timetable LV/LectureTimetableLV'
import ExamPass from './Pages/ExamPass/ExamPass';
import Exampass2 from './Pages/Exampass2/Exampass2';
import ExamTimetable from './Pages/Exam Timetable/ExamTimetable'
import ExamTimetableLV from './Pages/Exam Timetable LV copy/ExamTimetableLV'
import CheckResult from './Pages/CheckResult/CheckResult'
import NoResult from './Pages/NoResult/NoResult';
import Nothing from './Pages/Nothing/Nothing'
import NoData from './Pages/NoData/NoData'

function App() {
  return (
    <Router>
    <Routes>
      
      <Route path='/' element={<Login/>} />
      <Route path='/dashboard' element={<Dashboard/>} />
      <Route path='/navigation' element={<DashboardNav/>} />
      <Route path='/bio_data' element={<BioData/>} />
      <Route path='/reg' element={<Reg/>} />
      <Route path='/login' element={<Login/>} />
      <Route path='/registration' element={<Registration/>} />
      <Route path='/registration2' element={<Registration2/>} />
      <Route path='/profile' element={<Profile/>} />
      <Route path='/wallet' element={<Wallet/>} />
      <Route path='/virtualwallet' element={<VirtualWallet/>} />
      <Route path='/walletbal' element={<WalletBalance/>} />
      <Route path='/fundacct' element={<FundAccount/>} />
      <Route path='/transactionHry' element={<TransactionHry/>} />
      <Route path='/nothing' element={<Nothing/>} />
      <Route path='/no_data' element={<NoData/>} />
      
      <Route path='/transHtrNo' element={<TransHtrNo/>} />
      <Route path='/other_summary' element={<OtherSummary/>} />
      <Route path='/senate' element={<Senate/>} />
      <Route path='/senate2' element={<Senate2/>} />
      <Route path='/senate2' element={<Senate2/>} />
      <Route path='/school_summary' element={<SchoolFees2/>} />
      <Route path='/make_payment' element={<MakePayment/>} />
      <Route path='/make_payment_other' element={<MakePaymentOther/>} />
      <Route path='/paid_fee' element={<PaidFee/>} />
      <Route path='/no_fees' element={<NoFees/>} />
      <Route path='/other_fees' element={<OtherFees/>} />
      <Route path='/other_fees_paid' element={<OtherFeesPaid/>} />
      <Route path='/lecture_timetable' element={<LectureTimetable/>} />
      <Route path='/lecture_timetable_lv' element={<LectureTimetableLV/>} />
      <Route path='/exam_timetable' element={<ExamTimetable/>} />
      <Route path='/exam_timetable_lv' element={<ExamTimetableLV/>} />

      <Route path='/hostel' element={<Hostel/>} />
      <Route path='/hostelroom' element={<HostelRoom/>} />
      <Route path='/hostelfeessum' element={<HostelFeesSum/>} />
      <Route path='/hostelpay' element={<HostelPay/>} />
      <Route path='/hostelrect' element={<HostelReceipt/>} />
      <Route path='/hostelallo' element={<HostelAllocation/>} />
      <Route path='/hostelconf' element={<HostelConfirm/>} />

      <Route path='/coursemat' element={<CourseMaterial/>} />
      <Route path='/coursemat2' element={<CourseMaterial2/>} />

      <Route path='/assign' element={<Assignment/>} />
      <Route path='/newassign' element={<NewAssignment/>} />
      <Route path='/submitassign' element={<SubmitAssignment/>} />
      <Route path='/uploadassign' element={<UploadAssignment/>} />
      <Route path='/assignconf' element={<AssignmentSubmit/>} />

      <Route path='/exam_pass' element={<ExamPass/>} />
      <Route path='/exampass2' element={<Exampass2/>} /> 
      <Route path='/check_result' element={<CheckResult/>} />
      <Route path='/no_result' element={<NoResult/>} />

    </Routes>
    </Router>
);
}

export default App;