import React from "react";
import classes from '../CheckResult/CheckResult.module.css';
// import logo from "../../assets/FUNAAB-Logo 1.png"
// import {Routes, Route} from 'react-router-dom';
// import Login from "./Pages/Login/Login"
// import girlImg from "../../assets/girlImg.png";
import pfp from "../../Assets/pfp.png";
// import pysf from '../../assets/payurfees.png'
// import hostlfees from '../../assets/hostlfees.png'
// import fundurwlt from '../../assets/fundurwlt.png'
// import chekurrslt from '../../assets/chkurwlt.png'
// import gturcrsmtrls from '../../assets/gturcrsmtrls.png'
// import rgstrurcurses from '../../assets/rgstrurcurses.png'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import person1 from '../../Assets/person1.svg'
import stroke from '../../Assets/stroke.svg'
import person2 from '../../Assets/person2.svg'
import person3 from '../../Assets/person3.svg'
import wallet from '../../Assets/wallet.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'

function CheckResult() {
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>Check your result</p>
                    {/* <p className={classes.qlnktxt}>Quicklinks</p> */}
                    {/* <div className={classes.qlnks}>
                    
                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>

                    <div className={classes.frstcolumn}>

                        <div className={classes.frstsubcolumn}>
                            <img src={gturcrsmtrls} className={classes.gturcrsmtrls} />
                            <img src={chekurrslt} className={classes.chekurrslt} />
                        </div>
                        <img src={rgstrurcurses} className={classes.rgstrurcurses} />
                    </div>

                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>
                </div> */}
                    <div className={classes.dashBoardDiv}>
                        <h4>Use the filter to get your result for any semester</h4>
                        <div className={classes.filtercontainer}>
                            <label for="level">Select level:</label>
                            <select id="level">
                                <option>100 level</option>
                                {/* <!-- other options --> */}
                            </select>
                            <label for="semester">Select semester:</label>
                            <select id="semester">
                                <option>Second Semester</option>
                                {/* <!-- other options --> */}
                            </select>
                            <button id="show-result">Show result</button>
                        </div>

                        <h2>Result for 100 Level second semester</h2>
                        <table className={classes.resultTable}>
                            <thead>
                                <tr className={classes.firstHead}>
                                    <th colspan="2">2022/2023 SESSION</th>
                                    <th colspan="2">SECOND SEMESTER</th>
                                    <th colspan="2">100 LEVEL</th>
                                </tr>
                                <tr className={classes.secondHead}>
                                    <th>S/N</th>
                                    <th>Course Code</th>
                                    <th>Course Title</th>
                                    <th>Unit</th>
                                    <th>Grade</th>
                                    <th>Point</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>CSC 102</td>
                                    <td>INTRODUCTION TO COMPUTER LANGUAGE MODELS II</td>
                                    <td>3</td>
                                    <td>50C</td>
                                    <td>3</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>CSC 104</td>
                                    <td>INTRODUCTION TO COMPUTER LANGUAGE MODELS II</td>
                                    <td>4</td>
                                    <td>76A</td>
                                    <td>5</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>CCSC 106</td>
                                    <td>INTRODUCTION TO COMPUTER LANGUAGE MODELS II</td>
                                    <td>3</td>
                                    <td>48D</td>
                                    <td>2</td>
                                </tr>
                            </tbody>
                        </table>

                        <div className={classes.gpasummary}>
                            <p>Present CGPA: 3.61</p>
                            <p>Cumulative GPA: 3.55</p>
                            <p>Remarks: Good Standing</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default CheckResult;
