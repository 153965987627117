import React from "react";
import classes from './ExamTimetable.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation';
import Horheader from '../../Components/horheader/horheader';
import ExamView from '../../Components/Exam View2/ExamView';


const ExamTimetable = () => {


  return (
    <div className={classes.appcontainer}>
      <div className={classes.sidenav}>
        <DashboardNav className={classes.scrollable} />
      </div>

      <div className={classes.maincontent}>
        <Horheader />
        <p className={classes.dshbrdtxt}>Exam Timetable</p>

        <div className={classes.dashBoardDiv}>
          <ExamView />

          <div className={classes.examTbContainer}>
    <table className={classes.examTable}>
        <thead>
            <tr>
                <th colSpan="7" className={classes.calendarCaption}>
                    <p>Full Exam Timetable<br/><span>May 4, 2024 - May 19, 2024</span></p>
                </th>
            </tr>
            <tr>
                <th>Mon</th>
                <th>Tue</th>
                <th>Wed</th>
                <th>Thu</th>
                <th>Fri</th>
                <th>Sat</th>
                <th>Sun</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>
                    <div className={classes.eventDay}>
                        <div className={classes.verline}/>
                        <div className={classes.txtVer}>
                            <p className={classes.header1}>4</p>
                            <p className={classes.course}>CSC 201</p>
                            <p className={classes.fooTxt1}>8:00am - 10:00am</p>
                            <p className={classes.fooTxt}>Andersen Tedder hall </p>
                        </div>
                    </div>
                </td>
                <td>5</td>
                <td>
                    <div className={classes.eventDay}>
                        <div className={classes.verline}/>
                        <div className={classes.txtVer}>
                            <p className={classes.header1}>6</p>
                            <p className={classes.course}>MTS 201</p>
                            <p className={classes.fooTxt1}>8:00am - 10:00am</p>
                            <p className={classes.fooTxt}>1000 Lecture capacity hall </p>
                        </div>
                    </div>
                </td>
                <td>7</td>
            </tr>
            <tr>
            <td>
                    <div className={classes.eventDay}>
                        <div className={classes.verline}/>
                        <div className={classes.txtVer}>
                            <p className={classes.header1}>8</p>
                            <p className={classes.course}>CSC 201</p>
                            <p className={classes.fooTxt1}>8:00am - 10:00am</p>
                            <p className={classes.fooTxt}>Andersen Tedder hall </p>
                        </div>
                    </div>
                </td>
                <td>
                    <div className={classes.eventDay}>
                        <div className={classes.verline}/>
                        <div className={classes.txtVer}>
                            <p className={classes.header1}>9</p>
                            <p className={classes.course}>CSC 201</p>
                            <p className={classes.fooTxt1}>8:00am - 10:00am</p>
                            <p className={classes.fooTxt}>Andersen Tedder hall </p>
                        </div>
                    </div>
                </td>
                <td>10</td>
                <td>
                    <div className={classes.eventDay}>
                        <div className={classes.verline}/>
                        <div className={classes.txtVer}>
                            <p className={classes.header1}>11</p>
                            <p className={classes.course}>CSC 205</p>
                            <p className={classes.fooTxt1}>3:00pm - 5:00pm</p>
                            <p className={classes.fooTxt}>Andersen Tedder hall </p>
                        </div>
                    </div>
                </td>
                <td>12</td>
                <td>
                    <div className={classes.eventDay}>
                        <div className={classes.verline}/>
                        <div className={classes.txtVer}>
                            <p className={classes.header1}>13</p>
                            <p className={classes.course}>CSC 201</p>
                            <p className={classes.fooTxt1}>8:00am - 10:00am</p>
                            <p className={classes.fooTxt}>Andersen Tedder hall </p>
                        </div>
                    </div>
                </td>
                <td>14</td>
            </tr>
            <tr>
            <td>
                    <div className={classes.eventDay}>
                        <div className={classes.verline}/>
                        <div className={classes.txtVer}>
                            <p className={classes.header1}>15</p>
                            <p className={classes.course}>CSC 201</p>
                            <p className={classes.fooTxt1}>8:00am - 10:00am</p>
                            <p className={classes.fooTxt}>Andersen Tedder hall </p>
                        </div>
                    </div>
                </td>
                <td>
                    <div className={classes.eventDay}>
                        <div className={classes.verline}/>
                        <div className={classes.txtVer}>
                            <p className={classes.header1}>16</p>
                            <p className={classes.course}>CSC 201</p>
                            <p className={classes.fooTxt1}>8:00am - 10:00am</p>
                            <p className={classes.fooTxt}>Andersen Tedder hall </p>
                        </div>
                    </div>
                </td>
                <td>17</td>
                <td>18</td>
                <td>
                    <div className={classes.eventDay}>
                        <div className={classes.verline}/>
                        <div className={classes.txtVer}>
                            <p className={classes.header1}>19</p>
                            <p className={classes.course}>CSC 201</p>
                            <p className={classes.fooTxt1}>8:00am - 10:00am</p>
                            <p className={classes.fooTxt}>Andersen Tedder hall </p>
                        </div>
                    </div>
                </td>
                <td>20</td>
                <td>21</td>
            </tr>
            <tr>
                <td>22</td>
                <td>23</td>
                <td>
                    <div className={classes.eventDay}>
                        <div className={classes.verline}/>
                        <div className={classes.txtVer}>
                            <p className={classes.header1}>24</p>
                            <p className={classes.course}>CSC 201</p>
                            <p className={classes.fooTxt1}>8:00am - 10:00am</p>
                            <p className={classes.fooTxt}>Andersen Tedder hall </p>
                        </div>
                    </div>
                </td>
                <td>25</td>
                <td>26</td>
                <td>27</td>
                <td>28</td>
            </tr>
            <tr>
                <td>29</td>
                <td>
                    <div className={classes.eventDay}>
                        <div className={classes.verline}/>
                        <div className={classes.txtVer}>
                            <p className={classes.header1}>30</p>
                            <p className={classes.course}>CSC 201</p>
                            <p className={classes.fooTxt1}>8:00am - 10:00am</p>
                            <p className={classes.fooTxt}>Andersen Tedder hall </p>
                        </div>
                    </div>
                </td>
                <td>31</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </tbody>
    </table>
</div>

        </div>

      </div>

    </div>
  );
};

export default ExamTimetable;
