import React from "react";
import classes from '../Transaction History/TransctionHry.module.css';
// import logo from "../../assets/FUNAAB-Logo 1.png"
// import {Routes, Route} from 'react-router-dom';
// import Login from "./Pages/Login/Login"
// import girlImg from "../../assets/girlImg.png";
import pfp from "../../Assets/pfp.png";
// import pysf from '../../assets/payurfees.png'
// import hostlfees from '../../assets/hostlfees.png'
// import fundurwlt from '../../assets/fundurwlt.png'
// import chekurrslt from '../../assets/chkurwlt.png'
// import gturcrsmtrls from '../../assets/gturcrsmtrls.png'
// import rgstrurcurses from '../../assets/rgstrurcurses.png'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import fall from '../../Assets/clas.png';
import rise from '../../Assets/down.png'
import { Link } from "react-router-dom";

function BioData() {
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>Transaction History</p>
                    {/* <p className={classes.qlnktxt}>Quicklinks</p> */}
                    {/* <div className={classes.qlnks}>
                    
                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>

                    <div className={classes.frstcolumn}>

                        <div className={classes.frstsubcolumn}>
                            <img src={gturcrsmtrls} className={classes.gturcrsmtrls} />
                            <img src={chekurrslt} className={classes.chekurrslt} />
                        </div>
                        <img src={rgstrurcurses} className={classes.rgstrurcurses} />
                    </div>

                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>
                </div> */}
                    <div className={classes.dashBoardDiv}>
                        
                    <div className={classes.tableContainer}>
                        <table className={classes.transHtry}>

                            <thead>
                            <tr className={classes.thRow}>
                                <th>TYPE</th>
                                <th>AMOUNT</th>
                                <th>DATE</th>
                                <th>ACTION</th>
                            </tr>
                            </thead>

                            <tbody>
                            <tr className={classes.thRow}>
                                <td className={classes.tbData}>
                                <div className={classes.flow}>
                                    <img src={fall} className={classes.fall} alt="Fall" />
                                    <span>Transfer in from bank</span>
                                </div>
                                </td>
                                <td className={classes.tbData}>120,000</td>
                                <td className={classes.tbData}>April 15, 2024 - 11:43am</td>
                                <td className={classes.tbData}></td>
                            </tr>
                            <tr className={classes.thRow}>
                                <td className={classes.tbData}>
                                <div className={classes.flow}>
                                    <img src={rise} className={classes.fall} alt="Fall" />
                                    <span>Payment for first semester school fees for 200 level</span>
                                </div>
                                </td>
                                <td className={classes.tbData}>120,000</td>
                                <td className={classes.tbData}>April 16, 2024 - 05:18pm</td>
                                <td className={classes.tbData}>
                                    <Link to={'/no_data'}>
                                        <button className={classes.printReButton}>
                                        Print receipt
                                        </button>
                                    </Link>
                                </td>
                            </tr>
                            <tr className={classes.thRow}>
                                <td className={classes.tbData}>
                                <div className={classes.flow}>
                                    <img src={fall} className={classes.fall} alt="Fall" />
                                    <span>Transfer in from bank</span>
                                </div>
                                </td>
                                <td className={classes.tbData}>120,000</td>
                                <td className={classes.tbData}>April 15, 2024 - 11:43am</td>
                                <td className={classes.tbData}></td>
                            </tr>
                            <tr className={classes.thRow}>
                                <td className={classes.tbData}>
                                <div className={classes.flow}>
                                    <img src={rise} className={classes.fall} alt="Fall" />
                                    <span>Payment for first semester school fees for 200 level</span>
                                </div>
                                </td>
                                <td className={classes.tbData}>120,000</td>
                                <td className={classes.tbData}>April 16, 2024 - 05:18pm</td>
                                <td className={classes.tbData}>
                                    <Link to={'/no_data'}>
                                        <button className={classes.printReButton}>
                                        Print receipt
                                        </button>
                                    </Link>
                                </td>
                            </tr>
                            <tr className={classes.thRow}>
                                <td className={classes.tbData}>
                                <div className={classes.flow}>
                                    <img src={fall} className={classes.fall} alt="Fall" />
                                    <span>Transfer in from bank</span>
                                </div>
                                </td>
                                <td className={classes.tbData}>120,000</td>
                                <td className={classes.tbData}>April 15, 2024 - 11:43am</td>
                                <td className={classes.tbData}></td>
                            </tr>
                            <tr className={classes.thRow}>
                                <td className={classes.tbData}>
                                <div className={classes.flow}>
                                    <img src={rise} className={classes.fall} alt="Fall" />
                                    <span>Payment for first semester school fees for 200 level</span>
                                </div>
                                </td>
                                <td className={classes.tbData}>120,000</td>
                                <td className={classes.tbData}>April 16, 2024 - 05:18pm</td>
                                <td className={classes.tbData}>
                                    <Link to={'/no_data'}>
                                        <button className={classes.printReButton}>
                                        Print receipt
                                        </button>
                                    </Link>
                                </td>
                            </tr>
                            <tr className={classes.thRow}>
                                <td className={classes.tbData}>
                                <div className={classes.flow}>
                                    <img src={fall} className={classes.fall} alt="Fall" />
                                    <span>Transfer in from bank</span>
                                </div>
                                </td>
                                <td className={classes.tbData}>120,000</td>
                                <td className={classes.tbData}>April 15, 2024 - 11:43am</td>
                                <td className={classes.tbData}></td>
                            </tr>
                            <tr className={classes.thRow}>
                                <td className={classes.tbData}>
                                <div className={classes.flow}>
                                    <img src={rise} className={classes.fall} alt="Fall" />
                                    <span>Payment for first semester school fees for 200 level</span>
                                </div>
                                </td>
                                <td className={classes.tbData}>120,000</td>
                                <td className={classes.tbData}>April 16, 2024 - 05:18pm</td>
                                <td className={classes.tbData}>
                                    <Link to={'/no_data'}>
                                        <button className={classes.printReButton}>
                                        Print receipt
                                        </button>
                                    </Link>
                                </td>
                            </tr>
                           
                            </tbody>
                            
                        </table>
                    </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default BioData;
