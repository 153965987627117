import React from "react";
import classes from './SchoolFees2.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation';
import Horheader from '../../Components/horheader/horheader';
import { Link } from "react-router-dom";

function SchoolSummary() {
    return (
        <div className={classes.appcontainer}>
            <div className={classes.sidenav}>
                <DashboardNav className={classes.scrollable} />
            </div>

            <div className={classes.maincontent}>
                <Horheader />
                <p className={classes.dshbrdtxt}>School Fees</p>
                <div className={classes.dashBoardDiv}>
                    <div className={classes.summary}>
                        <h3>School Fees Summary</h3>
                        <p className={classes.underFees}>School fees for first semester 200 level Computer Science student</p>
                        <div className={classes.line} />
                        <div className={classes.tablez}>
                            <table>
                                <thead>
                                    <tr className={classes.theHead}>
                                        <td>Breakdown</td>
                                        <td>Amount</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className={classes.ttd}>Tuition</td>
                                        <td className={classes.ttd}>60,000</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.ttd}>Internet fees</td>
                                        <td className={classes.ttd}>2,000</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.ttd}>Faculty fees</td>
                                        <td className={classes.ttd}>5,000</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.ttd}>Department fees</td>
                                        <td className={classes.ttd}>2,000</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.ttd}>Faculty fees</td>
                                        <td className={classes.ttd}>5,000</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.ttd}>Department fees</td>
                                        <td className={classes.ttd}>2,000</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.ttd}>Faculty fees</td>
                                        <td className={classes.ttd}>5,000</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.ttd}>Department fees</td>
                                        <td className={classes.ttd}>2,000</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p className={classes.noteTxt}>NOTE</p>
                        <p className={classes.undNote}>Please ensure you pay your school fees before the 28th of June 2024, to avoid being de-registered for this semester</p>
                    </div>
                    <div className={classes.totalTbp}>
                        <div>
                            <p className={classes.toBp}>To be paid</p>
                            <h1 className={classes.toBpAmt}>N120,000</h1>
                        </div>
                        
                        <Link to={'/make_payment'} className={classes.def}>
                            <button className={classes.makePamtBtn}>
                                Make Payment
                            </button>
                        </Link>
                        
                       
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SchoolSummary;
