import React, { useState } from "react";
import classes from './LectureTimetableLV.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation';
import Horheader from '../../Components/horheader/horheader';
import CalenderView from '../../Components/Calender View/CalenderVIew';

const ListView = () => {
    const [showContainer, setShowContainer] = useState(false);

  return (
    <div className={classes.appcontainer}>
      <div className={classes.sidenav}>
        <DashboardNav className={classes.scrollable} />
      </div>

      <div className={classes.maincontent}>
        <Horheader />
        <p className={classes.dshbrdtxt}>Lectures Timetable</p>

        <div className={classes.dashBoardDiv}>
          <CalenderView />  
          <div className={classes.ListViewCont}>
            <table className={classes.ListViewTable}>
                <thead className={classes.LvHead}>
                    <th>S/N</th>
                    <th>Day</th>
                    <th>Course Code</th>
                    <th>Description</th>
                    <th>Time</th>
                    <th>Link</th>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>Monday</td>
                        <td>CSC 201</td>
                        <td>Computer programming and debugging</td>
                        <td>8:00am - 9:00am</td>
                        <td>
                        <button onClick={() => setShowContainer(!showContainer)}
                        className={classes.clLink}>Class link</button>
                        {showContainer && <div className={classes.greenshit}>
                            <h4>CSC 205</h4>
                            <small>Computer hardware and networking<br/>
systems</small>
                            <h4>LECTURER</h4>
                            <small>Dr Owonikoko Alade</small>
                            <p>8:00am - 9:00am</p>
                            <small>LINK TO CLASS</small>
                            <small>bngr-ynyr-evwr</small>
                            </div>}
                        </td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>Monday</td>
                        <td>MTS 201</td>
                        <td>Mathematics Logic and Syntaxes</td>
                        <td>12:009m - 1:00pm</td>
                        <td>
                            <button className={classes.clLink}>
                            Class link
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>Monday</td>
                        <td>GNS 201</td>
                        <td>Philosophy and Philosophical models</td>
                        <td>2:00pm - 3:00pm</td>
                        <td>
                            <button className={classes.clLink}>
                            Class link
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>Tuesday</td>
                        <td>CSC 203</td>
                        <td>Computer language and syntaxes</td>
                        <td>10:00am - 11:00am</td>
                        <td>
                            <button className={classes.clLink}>
                            Class link
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>Tuesday</td>
                        <td>MTS 203</td>
                        <td>Mathematical language models</td>
                        <td>3:00pm - 4:00pm</td>
                        <td>
                            <button className={classes.clLink}>
                            Class link
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>Wednesday</td>
                        <td>CSC 205</td>
                        <td>Computer hardware and networking systems</td>
                        <td>8:00am - 9:00am</td>
                        <td>
                            <button className={classes.clLink}>
                            Class link
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>Thursday</td>
                        <td>CSC 201</td>
                        <td>Introduction to computer language models</td>
                        <td>10:00am - 11:00am</td>
                        <td>
                            <button className={classes.clLink}>
                            Class link
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>Thursday</td>
                        <td>CSC 207</td>
                        <td>Computer history II</td>
                        <td>12:00pm - 1:00pm</td>
                        <td>
                            <button className={classes.clLink}>
                            Class link
                            </button>
                        </td>
                    </tr>
                    {/* <tr>
                        <td>7</td>
                        <td>Thursday</td>
                        <td>CSC 201</td>
                        <td>Introduction to computer language models</td>
                        <td>10:00am - 11:00am</td>
                        <td>
                            <button className={classes.clLink}>
                            Class link
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>Thursday</td>
                        <td>CSC 201</td>
                        <td>Introduction to computer language models</td>
                        <td>10:00am - 11:00am</td>
                        <td>
                            <button className={classes.clLink}>
                            Class link
                            </button>
                        </td>
                    </tr> */}
                </tbody>
            </table>
          </div>
        </div>

      </div>

    </div>
  );
};

export default ListView;
