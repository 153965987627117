import React from "react";
import classes from '../CourseMaterial/CourseMaterial.module.css';
// import logo from "../../assets/FUNAAB-Logo 1.png"
// import {Routes, Route} from 'react-router-dom';
// import Login from "./Pages/Login/Login"
// import girlImg from "../../assets/girlImg.png";
import pfp from "../../Assets/pfp.png";
// import pysf from '../../assets/payurfees.png'
// import hostlfees from '../../assets/hostlfees.png'
// import fundurwlt from '../../assets/fundurwlt.png'
// import chekurrslt from '../../assets/chkurwlt.png'
// import gturcrsmtrls from '../../assets/gturcrsmtrls.png'
// import rgstrurcurses from '../../assets/rgstrurcurses.png'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import bookicon from '../../Assets/bookicon.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'

function CourseMaterial() {
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>Course Materials</p>
                    {/* <p className={classes.qlnktxt}>Quicklinks</p> */}
                    {/* <div className={classes.qlnks}>
                    
                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>

                    <div className={classes.frstcolumn}>

                        <div className={classes.frstsubcolumn}>
                            <img src={gturcrsmtrls} className={classes.gturcrsmtrls} />
                            <img src={chekurrslt} className={classes.chekurrslt} />
                        </div>
                        <img src={rgstrurcurses} className={classes.rgstrurcurses} />
                    </div>

                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>
                </div> */}
                    <div className={classes.dashBoardDiv}>
                        <div className={classes.coursecontainer}>
                            <div className={classes.courseitem}>
                            <Link to={'/coursemat2'}><img src={bookicon} alt="Course Icon" className={classes.courseicon }/></Link>
                                    <div className={classes.coursedetails}>
                                    <Link to={'/coursemat2'}><h4 className={classes.coursetitle}>CSC 201</h4></Link>
                                        <Link to={'/coursemat2'}><p className={classes.coursedescription}>Computer programming and debugging</p></Link>
                                       <Link to={'/coursemat2'}><p className={classes.coursedownload}>Download course materials</p></Link>
                                    </div>
                            </div>
                            <div className={classes.courseitem}>
                            <Link to={'/coursemat2'}><img src={bookicon} alt="Course Icon" className={classes.courseicon }/></Link>
                                    <div className={classes.coursedetails}>
                                    <Link to={'/coursemat2'}><h4 className={classes.coursetitle}>CSC 201</h4></Link>
                                        <Link to={'/coursemat2'}><p className={classes.coursedescription}>Computer programming and debugging</p></Link>
                                       <Link to={'/coursemat2'}><p className={classes.coursedownload}>Download course materials</p></Link>
                                    </div>
                            </div>
                        </div>

                        <div className={classes.coursecontainer}>
                            <div className={classes.courseitem}>
                            <Link to={'/coursemat2'}><img src={bookicon} alt="Course Icon" className={classes.courseicon }/></Link>
                                    <div className={classes.coursedetails}>
                                    <Link to={'/coursemat2'}><h4 className={classes.coursetitle}>CSC 201</h4></Link>
                                        <Link to={'/coursemat2'}><p className={classes.coursedescription}>Computer programming and debugging</p></Link>
                                       <Link to={'/coursemat2'}><p className={classes.coursedownload}>Download course materials</p></Link>
                                    </div>
                            </div>
                            <div className={classes.courseitem}>
                            <Link to={'/coursemat2'}><img src={bookicon} alt="Course Icon" className={classes.courseicon }/></Link>
                                    <div className={classes.coursedetails}>
                                    <Link to={'/coursemat2'}><h4 className={classes.coursetitle}>CSC 201</h4></Link>
                                        <Link to={'/coursemat2'}><p className={classes.coursedescription}>Computer programming and debugging</p></Link>
                                       <Link to={'/coursemat2'}><p className={classes.coursedownload}>Download course materials</p></Link>
                                    </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>

    );
}

export default CourseMaterial;
