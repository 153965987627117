import React from 'react';
import DashboardNav from '../Components/Navigation.js/Navigation'
import Horheader from '../Components/horheader/horheader'
import classes from './Dashboard.module.css'
import pysf from '../Assets/payurfees.png'
import hostlfees from '../Assets/hostlfees.png'
import fundurwlt from '../Assets/fundurwlt.png'
import chekurrslt from '../Assets/chkurwlt.png'
import gturcrsmtrls from '../Assets/gturcrsmtrls.png'
import rgstrurcurses from '../Assets/rgstrurcurses.png'
import { Link, useLocation, useNavigate } from 'react-router-dom'



const Dashboard = () => {

    return (

        <div className={classes.appcontainer}>
            <div className={classes.sidenav}>
                <DashboardNav className={classes.scrollable} />
            </div>

            <div className={classes.maincontent}>
                <Horheader />
                <p className={classes.dshbrdtxt}>Dashboard</p>
                <p className={classes.qlnktxt}>Quicklinks</p>
                <div className={classes.qlnks}>
                    
                    <div className={classes.frstcolumn}>
                    <Link
                        to={'/school_summary'}><img src={pysf} className={classes.pysf} /></Link>
                        <div className={classes.frstsubcolumn} >
                        <Link
                        to={'/hostel'}><img src={hostlfees} className={classes.hostlfees} /></Link>
                            <Link
                        to={'/fundacct'}><img src={fundurwlt} className={classes.fundurwlt} /></Link>
                        </div>

                    </div>

                    <div className={classes.frstcolumn}>

                        <div className={classes.frstsubcolumn}>
                        <Link
                        to={'/coursemat'}><img src={gturcrsmtrls} className={classes.gturcrsmtrls} /></Link>
                             <Link
                        to={'/check_result'}><img src={chekurrslt} className={classes.chekurrslt} /></Link>
                        </div>
                        <Link
                        to={'/registration'}><img src={rgstrurcurses} className={classes.rgstrurcurses} /></Link>
                    </div>

                    <div className={classes.frstcolumn}>
                    <Link
                        to={'/make_payment'}><img src={pysf} className={classes.pysf} /></Link>
                        <div className={classes.frstsubcolumn} >
                        <Link
                        to={'/hostelfeessum'}><img src={hostlfees} className={classes.hostlfees} /></Link>
                            <Link
                        to={'/wallet'}><img src={fundurwlt} className={classes.fundurwlt} /></Link>
                        </div>

                    </div>
                </div>
            </div>

        </div>

    );
}

export default Dashboard;