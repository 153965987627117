import React from "react";
import classes from '../Registration2/Registration2.module.css';
// import logo from "../../assets/FUNAAB-Logo 1.png"
// import {Routes, Route} from 'react-router-dom';
// import Login from "./Pages/Login/Login"
// import girlImg from "../../assets/girlImg.png";
import pfp from "../../Assets/pfp.png";
// import pysf from '../../assets/payurfees.png'
// import hostlfees from '../../assets/hostlfees.png'
// import fundurwlt from '../../assets/fundurwlt.png'
// import chekurrslt from '../../assets/chkurwlt.png'
// import gturcrsmtrls from '../../assets/gturcrsmtrls.png'
// import rgstrurcurses from '../../assets/rgstrurcurses.png'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import { Link, useLocation, useNavigate } from 'react-router-dom'

function BioData() {
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>Registration</p>
                    {/* <p className={classes.qlnktxt}>Quicklinks</p> */}
                    {/* <div className={classes.qlnks}>
                    
                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>

                    <div className={classes.frstcolumn}>

                        <div className={classes.frstsubcolumn}>
                            <img src={gturcrsmtrls} className={classes.gturcrsmtrls} />
                            <img src={chekurrslt} className={classes.chekurrslt} />
                        </div>
                        <img src={rgstrurcurses} className={classes.rgstrurcurses} />
                    </div>

                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>
                </div> */}
                    <div className={classes.dashBoardDiv}>
                        
                        <div className={classes.headerText}>
                            <h4>Courses Registered</h4>
                            <p>Print out your course form here and submit it to your department to complete your registration </p>
                        </div>
                        <div className={classes.tableData}>
                            <table className={classes.dreg}>
                                <tbody>
                                    <tr>
                                        <td>Course Code</td>
                                        <td>Course description</td>
                                        <td>Course Unit</td>
                                    </tr>
                                    <tr>
                                        <td>CSC 201</td>
                                        <td>Computer programming and debugging</td>
                                        <td>3</td>
                                    </tr>
                                    <tr>
                                        <td>CSC 203</td>
                                        <td>Computer Language and Syntaxes</td>
                                        <td>2</td>
                                    </tr>
                                    <tr>
                                        <td>CSC 205</td>
                                        <td>Computer hardware and networking systems</td>
                                        <td>2</td>
                                    </tr>
                                    <tr>
                                        <td>CSC 207</td>
                                        <td>Computer History II</td>
                                        <td>2</td>
                                    </tr>
                                    <tr>
                                        <td>MTS 201</td>
                                        <td>Mathematics Logic and Syntaxes</td>
                                        <td>2</td>
                                    </tr>
                                    <tr>
                                        <td>GNS 201</td>
                                        <td>Philosophy and Philosophical models</td>
                                        <td>1</td>
                                    </tr>
                                    {/* <tr>
                                        <td>MTS 203</td>
                                        <td>Mathematical language models</td>
                                        <td>2</td>
                                    </tr>
                                    <tr>
                                        <td>BIO 211</td>
                                        <td>Introduction to human Interaction</td>
                                        <td>2</td>
                                    </tr>
                                    <tr>
                                        <td>CSC 101</td>
                                        <td>Introduction to Computer Language models</td>
                                        <td >2</td>
                                    </tr> */}
                                    
                                </tbody>
                            </table>
                        </div>
                        <Link to={'/reg'}><button className={classes.btnDesign}>Print Course form</button></Link>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default BioData;
