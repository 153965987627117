import React from "react";
import classes from './OtherSummary.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation';
import Horheader from '../../Components/horheader/horheader';
import { Link } from "react-router-dom";
import backIcon from '../../Assets/backIcon.png'

function SchoolSummary() {
    return (
        <div className={classes.appcontainer}>
            <div className={classes.sidenav}>
                <DashboardNav className={classes.scrollable} />
            </div>

            <div className={classes.maincontent}>
                <Horheader />
                <p className={classes.dshbrdtxt}>Other Fees</p>
                <div className={classes.dashBoardDiv}>
                        <div className={classes.backTxt}>
                            <img src={backIcon} className={classes.backIcon}/>
                            <small>Other Fees</small>
                        </div>
                    <div className={classes.new}>
                       <div className={classes.summary}>
                        <h3>Field Trip Summary</h3>
                        <p className={classes.underFees}>Field Trip to NIITDA for all 200 level computer science students</p>
                        <div className={classes.line} />
                        <div className={classes.tablez}>
                            <table>
                                <thead>
                                    <tr className={classes.theHead}>
                                        <td>Breakdown</td>
                                        <td>Amount</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className={classes.ttd}>Tuition</td>
                                        <td className={classes.ttd}>60,000</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.ttd}>Internet fees</td>
                                        <td className={classes.ttd}>2,000</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.ttd}>Faculty fees</td>
                                        <td className={classes.ttd}>5,000</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.ttd}>Department fees</td>
                                        <td className={classes.ttd}>2,000</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.ttd}>Faculty fees</td>
                                        <td className={classes.ttd}>5,000</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.ttd}>Department fees</td>
                                        <td className={classes.ttd}>2,000</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.ttd}>Faculty fees</td>
                                        <td className={classes.ttd}>5,000</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.ttd}>Department fees</td>
                                        <td className={classes.ttd}>2,000</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p className={classes.noteTxt}>NOTE</p>
                        <p className={classes.undNote}>Please ensure you pay for this field trip before 3rd of May 2024 in order to book your spot on the trip.</p>
                    </div> 

                    <div className={classes.totalTbp}>
                        <div>
                            <p className={classes.toBp}>To be paid</p>
                            <h1 className={classes.toBpAmt}>N30,000</h1>
                        </div>
                        
                        <Link to={'/make_payment'} className={classes.def}>
                            <button className={classes.makePamtBtn}>
                                Make Payment
                            </button>
                        </Link>
                        
                       
                    </div>

                    </div>
                    
                   
                </div>
            </div>
        </div>
    );
}

export default SchoolSummary;
