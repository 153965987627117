import React from "react";
import classes from '../HostelPay/HostelPay.module.css';
// import logo from "../../assets/FUNAAB-Logo 1.png"
// import {Routes, Route} from 'react-router-dom';
// import Login from "./Pages/Login/Login"
// import girlImg from "../../assets/girlImg.png";
import pfp from "../../Assets/pfp.png";
// import pysf from '../../assets/payurfees.png'
// import hostlfees from '../../assets/hostlfees.png'
// import fundurwlt from '../../assets/fundurwlt.png'
// import chekurrslt from '../../assets/chkurwlt.png'
// import gturcrsmtrls from '../../assets/gturcrsmtrls.png'
// import rgstrurcurses from '../../assets/rgstrurcurses.png'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import htelicon from '../../Assets/undraw_coming_home_re_ausc 1.png'
import back from '../../Assets/backicon.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'

function HostelPay() {
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>Hostel Fees</p>
                    {/* <p className={classes.qlnktxt}>Quicklinks</p> */}
                    {/* <div className={classes.qlnks}>
                    
                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>

                    <div className={classes.frstcolumn}>

                        <div className={classes.frstsubcolumn}>
                            <img src={gturcrsmtrls} className={classes.gturcrsmtrls} />
                            <img src={chekurrslt} className={classes.chekurrslt} />
                        </div>
                        <img src={rgstrurcurses} className={classes.rgstrurcurses} />
                    </div>

                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>
                </div> */}
                    <div className={classes.dashBoardDiv}>
                        <div className={classes.container}>
                            <div className={classes.subContainer}>
                                <img src={back} />
                                <p>Hostel selection</p>
                            </div>
                            <div className={classes.paymentsummary}>
                                <h4>Make Payment</h4>
                                <div className={classes.hostelfees}>
                                    <p>Hostel fees for Hostel A</p>
                                    <h4>N50,000</h4>
                                </div>
                            </div>
                            <div className={classes.paymentmd}>
                                <div className={classes.paymentmethod}>
                                    <h4>Select Payment Method</h4>
                                    <div className={classes.radiogroup}>
                                        <label className={classes.radioption}>
                                            <input type="radio" name="payment-method" checked />
                                            <span>
                                                <strong>Pay with Wallet balance</strong><br />
                                                <h4>Wallet balance - N150,000</h4>
                                            </span>
                                        </label>
                                        <label className={classes.radioption}>
                                            <input type="radio" name="payment-method" />
                                            <span>
                                                <strong>Pay with Remita</strong><br />
                                                <h4>Make your payment with remita payment system</h4>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className={classes.paymentdetails}>
                                    <h4>Payment details</h4>
                                    <p>Subtotal: <span>N50,000</span></p>
                                    <p>Tax: <span>0</span></p>
                                    <p>Total: <span>N50,000</span></p>
                                    <Link to={'/hostelrect'}><button className={classes.paymentbutton}>Make Payment</button></Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default HostelPay;
