import React from "react";
import classes from '../NoResult/NoResult.module.css';
// import logo from "../../assets/FUNAAB-Logo 1.png"
// import {Routes, Route} from 'react-router-dom';
// import Login from "./Pages/Login/Login"
// import girlImg from "../../assets/girlImg.png";
import pfp from "../../Assets/pfp.png";
// import pysf from '../../assets/payurfees.png'
// import hostlfees from '../../assets/hostlfees.png'
// import fundurwlt from '../../assets/fundurwlt.png'
// import chekurrslt from '../../assets/chkurwlt.png'
// import gturcrsmtrls from '../../assets/gturcrsmtrls.png'
// import rgstrurcurses from '../../assets/rgstrurcurses.png'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import htelicon from '../../Assets/undraw_coming_home_re_ausc 1.png'
import simg from '../../Assets/printreceipt.svg'
import noresult from '../../Assets/noresult.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'

function NoResult() {
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>Check your result</p>
                    {/* <p className={classes.qlnktxt}>Quicklinks</p> */}
                    {/* <div className={classes.qlnks}>
                    
                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>

                    <div className={classes.frstcolumn}>

                        <div className={classes.frstsubcolumn}>
                            <img src={gturcrsmtrls} className={classes.gturcrsmtrls} />
                            <img src={chekurrslt} className={classes.chekurrslt} />
                        </div>
                        <img src={rgstrurcurses} className={classes.rgstrurcurses} />
                    </div>

                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>
                </div> */}
                    <div className={classes.dashBoardDiv}>
                    <h4>Use the filter to get your result for any semester</h4>
                        <div className={classes.filtercontainer}>
                            <label for="level">Select level:</label>
                            <select id="level">
                                <option>100 level</option>
                                {/* <!-- other options --> */}
                            </select>
                            <label for="semester">Select semester:</label>
                            <select id="semester">
                                <option>Second Semester</option>
                                {/* <!-- other options --> */}
                            </select>
                            <button id="show-result">Show result</button>
                        </div>
                        <div className={classes.paymentsuccess}>
                            <img src={noresult} alt="No result" className={classes.classessuccessimage} />
                            <p className={classes.successmessage}>No data to show here at the moment</p>
                        </div>

                    </div>
                </div>
            </div>

        </div>

    );
}

export default NoResult;
